import React, { useEffect, useState, useRef } from "react";
import {
  getTestimonials,
  updateTestimonials,
} from "../../redux/Slices/testimonialSlice";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const UpdateTestimonial = ({ handleClose, updateId, testimonialtoupdate }) => {
  console.log(testimonialtoupdate, "testimonialtoupdate");

  const dispatch = useDispatch();
  const nameRef = useRef(null);
  const designationRef = useRef(null);
  const reviewRef = useRef(null);
  // const statusRef = useRef('1');
  const [status, setStatus] = useState("1");
  const [file, setFile] = useState(null);
  const handleChangeStatus = (e) => {
    setStatus(e.target.value); // Update the status state
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (nameRef.current.value) {
      formData.append("name", nameRef.current.value);
    }
    if (designationRef.current.value) {
      formData.append("designation", designationRef.current.value);
    }
    if (status) {
      formData.append("status", status);
    }
    if (reviewRef.current.value) {
      formData.append("review", reviewRef.current.value);
    }
    if (file) {
      formData.append("image", file);
    }
    if (updateId) {
      formData.append("testimonial_id", updateId);
    }

    try {
      dispatch(updateTestimonials(formData)).then((data) => {
        dispatch(getTestimonials());
        handleClose();
        toast.success("Updated Successfully!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };
  const handleFile = (e) => {
    setFile(e.target.files[0]);
  };
  useEffect(() => {}, []);
  return (
    <div>
      <Box sx={style}>
        <Button color="error" onClick={handleClose} sx={{ marginLeft: "80%" }}>
          <CancelOutlinedIcon />
        </Button>
        <Typography variant="h6">
          Updating testimonial No. {updateId}
        </Typography>

        <form
          onSubmit={handleSubmit}
          encType="multipart/form-data"
          style={{
            backgroundColor: "#ececec",
            padding: "20px",
            margin: "5px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <TextField
              label="Name"
              defaultValue={testimonialtoupdate[1]}
              inputRef={nameRef}
              sx={{ width: "48%" }}
            />
            <TextField
              label="Designation"
              defaultValue={testimonialtoupdate[2]}
              inputRef={designationRef}
              sx={{ width: "48%" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <TextField
              label="Review"
              multiline
              defaultValue={testimonialtoupdate[3]}
              rows={4}
              inputRef={reviewRef}
              sx={{ width: "48%" }}
            />
            <FormControl sx={{ width: "48%" }}>
              <InputLabel>Status</InputLabel>
              <Select required value={status} onChange={handleChangeStatus}>
                <MenuItem value="1">Active</MenuItem>
                <MenuItem value="0">Inactive</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <TextField type="file" name="image" onChange={handleFile} />
          </div>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            style={{ alignSelf: "center" }}
          >
            Submit
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default UpdateTestimonial;
