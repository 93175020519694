import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSeoDetails } from "../../redux/Slices/addSeoSlice";
import AddDetails from "./AddDetails";
import DataTable from "./SeoDatatable";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

export default function AllDetails() {
  const [seoDetails, setSeoDetails] = useState([]);
  const [update, setUpdate] = useState(false);
  const dispatch = useDispatch();
  const getDetails = useSelector((state) => state.seo.testTable);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getSeoDetails());
      } catch (error) {
        console.log("Error fetching SEO details:", error);
      }
    };
    fetchData();
  }, [update]);

    useEffect(() => {
        if (getDetails && getDetails.data && getDetails.data.length > 0) {
            const formattedSeo = getDetails.data.map((seoo, index) => ({
                id: seoo.id,
                sl: index + 1,
                title: seoo.seotitle,
                description: seoo.seodesc,
                data: seoo.page,
                active: seoo.active,
            }));
            setSeoDetails(formattedSeo);
        } else {
            setSeoDetails([]);
        }
    }, [getDetails, update]);


  console.log(seoDetails, "seoDetails");
  return (
    <div>
      <AddDetails setUpdate={setUpdate} />
      <DataTable rows={seoDetails} setUpdate={setUpdate} />
    </div>
  );
}
