import React, { useRef, useState } from 'react';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Grid,
  Box,
  Typography,
  CircularProgress
} from '@mui/material';
import { Send } from '@mui/icons-material';
import { toast } from 'react-toastify'
import { createTestimonials, getTestimonials } from '../../redux/Slices/testimonialSlice';
import { useDispatch } from 'react-redux';



const AddTestimonial = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('');
  const nameRef = useRef(null);
  const designationRef = useRef(null);
  const reviewRef = useRef(null);
  // const statusRef = useRef('1');
  const [file, setFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeStatus = (e) => {
    setStatus(e.target.value); // Update the status state
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('name', nameRef.current ? nameRef.current.value : '');
    formData.append('designation', designationRef.current ? designationRef.current.value : '');
    formData.append('status', status || '1');
    formData.append('review', reviewRef.current ? reviewRef.current.value : '');
    formData.append('image', file);
    setIsLoading(true);
    try {
      await dispatch(createTestimonials(formData));
      await dispatch(getTestimonials());

      e.target.reset();
      toast.success('Testimonial Added Successfully!', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } catch (error) {
      toast.error('Something went wrong', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      console.error('Error creating testimonial:', error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleFile = (e) => {

    setFile(e.target.files[0])
  }
  return (
    <>
      <Typography variant="h4" sx={{ paddingLeft: '20px' }}>
        Add Testimonials
      </Typography>
      <form onSubmit={handleSubmit} encType="multipart/form-data" style={{ padding: '20px', display: 'flex', flexDirection: 'column' }}>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>

          <TextField
            label="Name"
            required
            inputRef={nameRef}
            sx={{ width: '48%' }}
          />
          <TextField
            label="Designation"
            required
            inputRef={designationRef}
            sx={{ width: '48%' }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          <TextField
            label="Review"
            required
            multiline
            rows={4}
            inputRef={reviewRef}
            sx={{ width: '48%' }}
          />
          <FormControl sx={{ width: '48%' }}>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              required
              value={status}
              onChange={handleChangeStatus}
            >
              <MenuItem value="1">Active</MenuItem>
              <MenuItem value="0">Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ marginBottom: '20px' }}>
          <TextField
            type="file"
            required
            name="image"
            onChange={handleFile}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button
            sx={{ width: '10%', textAlign: 'center' }}
            type="submit"
            variant="contained"
            color="primary"
            startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <Send />}
            disabled={isLoading}
          >
            {isLoading ? 'Adding...' : 'Add'}
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddTestimonial;
