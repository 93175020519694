import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
const LoginSignupRoutes = () => {
  const userState = useSelector((state) => state.user);

  const authenticated = userState.isAuthenticated;
  console.log("loginsignupRendered")

  return (
    <div>
      {authenticated  ? (
        <Navigate to="/" replace />
      ) : (
        <Outlet />
      )}
    </div>
  );
};

export default LoginSignupRoutes;