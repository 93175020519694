import React, { useEffect, useState } from "react";
import { Paper, Avatar, Grid, Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getTestimonials,
  deleteTestimonials,
} from "../../redux/Slices/testimonialSlice";
import Datatable from "../mui-datatable/Datatable";
import AddTestimonial from "./AddTestimonial";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import UpdateTestimonial from "./UpdateTestimonial";
import { toast } from "react-toastify";
const AllTestimonial = () => {
  const auth = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const alltest = useSelector((state) => state.testimonial.testTable);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [updateId, setUpdateId] = useState();
  const [testimonialtoupdate, setTestimonialtoupdate] = useState([]);
  const [open, setOpen] = useState(false);
  const handleOpen = (rowData) => {
    console.log(rowData, "rowData");
    setOpen(true);
    setUpdateId(rowData?.[0]);
    setTestimonialtoupdate(rowData);
  };

  const handleClose = () => setOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (auth) {
          dispatch(getTestimonials())
            .then((data) => {
              console.log(data, "data");
              if (data.payload?.code == 200) {
                toast.success("Data loaded successfully!", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
              } else {
                toast.error("Something went wrong", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
              }
            })
            .catch((err) => {
              console.log(err, "err in recent removed ext");
              toast.error("Something went wrong", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
              });
            });
        }
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };
    fetchData();
  }, [auth, dispatch]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (value) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const renderImageCell = (value) => {
    if (value) {
      // const objectUrl = URL.createObjectURL(blob)
      const base64String = btoa(
        String.fromCharCode(...new Uint8Array(value.data))
      );

      const imageUrl = `http://localhost:3000/images/${encodeURIComponent(
        value
      )}`;

      return <Avatar alt="Image" src={imageUrl} />;
    }
    return null;
  };

  const columns =
    alltest && alltest?.data.length > 0 ? Object.keys(alltest?.data[0]) : [];
  const filteredColumns = columns.filter((col) => col !== "image");

  const handleDelete = (testimonial_id) => {
    const formData = {
      testimonial_id: testimonial_id,
    };
    console.log(testimonial_id);
    try {
      dispatch(deleteTestimonials(formData)).then((data) => {
        console.log(data, "data");
        if (data?.payload?.code === 200) {
          dispatch(getTestimonials());
          toast.success("Deleted Successfully!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else {
          toast.error("Something went wrong!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      });
    } catch (error) {
      toast.error("Something went wrong!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };
  const renderActionCell = (value, tableMeta) => {
    const testimonial_id = tableMeta.rowData[0];

    // console.log(alltest, "alltest");

    return (
      <div>
        <IconButton>
          <EditIcon onClick={() => handleOpen(tableMeta.rowData)} />
        </IconButton>
        <IconButton>
          <DeleteIcon onClick={() => handleDelete(testimonial_id)} />
        </IconButton>
      </div>
    );
  };

  const options = {
    selectableRows: "none",
    filter: true,
    print: false,
    download: false,
    pagination: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 15],
    page: page,
    onChangePage: handlePageChange,
    onChangeRowsPerPage: handleRowsPerPageChange,
    customBodyRender: (value, tableMeta) => {
      if (tableMeta.columnData.name === "image") {
        return renderImageCell(value);
      } else if (tableMeta.columnData.name === "actions") {
        return renderActionCell(value, tableMeta);
      }
      return value;
    },
  };

  const columnsWithImageAndActions = [
    ...filteredColumns.map((col) => ({
      name: col,
      label: col,
    })),
    {
      name: "image",
      label: "Image",
      options: {
        customBodyRender: (value) => renderImageCell(value),
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta) =>
          renderActionCell(value, tableMeta),
      },
    },
  ];

  return (
    <Paper
      sx={{
        width: "80%",
        float: "right",
        overflow: "auto",
        marginTop: "6%",
        marginRight: "23px",
        marginBottom: "30px",
        paddingTop: "20px",
      }}
    >
      <AddTestimonial />
      <Grid sx={{ marginBottom: "100px" }}>
        {alltest?.data && (
          <Datatable
            title="All Testimonials"
            data={alltest?.data}
            columns={columnsWithImageAndActions}
            options={options}
          />
        )}
      </Grid>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <UpdateTestimonial
          handleClose={handleClose}
          updateId={updateId}
          testimonialtoupdate={testimonialtoupdate}
        />
      </Modal>
    </Paper>
  );
};

export default AllTestimonial;
