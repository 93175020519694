import * as React from "react";
import { useEffect, useState } from "react";
import {
  // InputLabel,
  // Paper,
  useTheme,
  TextField,
  // Grid,
  // Box,
  // Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
// import Button from "@mui/material/Button";
// import DeleteIcon from "@mui/icons-material/Delete";
// import SendIcon from "@mui/icons-material/Send";
// import Stack from "@mui/material/Stack";
import { getSeoDetails, createSeo } from "../../redux/Slices/addSeoSlice";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AddDetails({ setUpdate }) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [personName, setPersonName] = React.useState([]);
  const [title, setTitle] = useState(null);
  const [description, setDesc] = useState(null);
  const [data, setData] = useState(null);

  const notify = (value) =>
    toast.success(value, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const failure = (value) =>
    toast.error(value, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  function ComboBox({ onChange }) {
    return (
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={pageList}
        value={data}
        onChange={(event, newValue) => {
          // Check if newValue is not null before accessing 'label'
          onChange(newValue ? newValue.label : null);
        }}
        sx={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label="Select" />}
      />
    );
  }

  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
  const pageList = [
    { label: "/extension-stats" },
    { label: "/featured" },
    { label: "/most-downloaded" },
    { label: "/top-grossing" },
    { label: "/manifest-v2" },
    { label: "/manifest-v3" },
    { label: "/categories" },
    { label: "/recently-added" },
    { label: "/recently-updated" },
    { label: "/recently-removed" },
    { label: "/" },
    { label: "/about-us" },
    { label: "/contact-us" },
    { label: "/login" },
    { label: "/register" },
    { label: "/pricing" },
    { label: "/privacy-policy" },
    { label: "/search" },
    { label: "/terms-conditions" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const seodata = { title, description, data };
    dispatch(createSeo(seodata))
      .then((response) => {
        setTitle(" ");
        setDesc(" ");
        setData(" ");
        // console.log(response, "response");
        if (response.payload.code == 201) {
          notify(response.payload.message);
        }
        if (response.payload.code == 400) {
          failure(response.payload.message);
        }
      })
      .catch((error) => {
        console.log(error);
        failure(error);
      });
    setUpdate((prev) => !prev);
  };

  return (
    <div>
      <div
        style={{
          paddingTop: "5%",
          display: "flex",
          gap: "20%",
          textAlign: "center",
        }}
      >
        <h1 style={{ paddingLeft: "18%" }}>Add Data</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            gap: "20%",
            marginLeft: "10px",
            paddingLeft: "18%",
            justifyContent: "space-around",
            marginBottom: "30px",
          }}
        >
          <div style={{ width: "100%" }}>Seo Title</div>
          <div style={{ width: "100%", marginRight: "15px" }}>
            <TextField
              value={title}
              fullWidth
              id="fullWidth"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
        </div>

        {/* <div style={{ display: "flex", gap: "20%", textAlign: "center" }}>
          <div
            className="left"
            style={{
              paddingTop: "20px",
              marginLeft: "10px",
              flex: 1,
              marginLeft: "10%",
            }}
          >
            Seo Title
          </div>
          <div className="right" style={{ flex: 1, marginRight: "2%" }}>
            <TextField
              value={title}
              fullWidth
              id="fullWidth"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
        </div> */}

        <div
          style={{
            display: "flex",
            gap: "20%",
            marginLeft: "10px",
            paddingLeft: "18%",
            justifyContent: "space-around",
            marginBottom: "30px",
          }}
        >
          <div style={{ width: "100%" }}>Seo Desc</div>
          <div style={{ width: "100%", marginRight: "15px" }}>
            <TextField
              value={description}
              fullWidth
              id="fullWidth"
              onChange={(e) => {
                setDesc(e.target.value);
              }}
            />
          </div>
        </div>

        {/* <div
          style={{
            paddingTop: "10px",
            display: "flex",
            gap: "20%",
            textAlign: "center",
          }}
        >
          <div
            className="left"
            style={{
              paddingTop: "20px",
              marginLeft: "10px",
              flex: 1,
              marginLeft: "10%",
            }}
          >
            Seo Desc
          </div>
          <div className="right" style={{ flex: 1, marginRight: "2%" }}>
            <TextField
              value={description}
              fullWidth
              id="fullWidth"
              onChange={(e) => {
                setDesc(e.target.value);
              }}
            />
          </div>
        </div> */}

        <div
          style={{
            display: "flex",
            gap: "20%",
            marginLeft: "10px",
            paddingLeft: "18%",
            justifyContent: "space-around",
            marginBottom: "30px",
          }}
        >
          <div style={{ width: "100%" }}>Seo Data</div>
          <div style={{ width: "100%", marginRight: "15px" }}>
            <ComboBox onChange={setData} />
          </div>
        </div>

        {/* <div
          style={{
            marginLeft: "3px",
            display: "flex",
            gap: "20%",
            textAlign: "center",
          }}
        >
          <div
            className="left"
            style={{
              paddingTop: "20px",
              marginLeft: "10px",
              flex: 1,
              marginLeft: "10%",
            }}
          >
            Seo Data
          </div>
          <div
            className="right"
            style={{ flex: 1, marginRight: "2%", paddingTop: "10px" }}
          >
            
            <ComboBox onChange={setData} />
          </div>
        </div> */}

        <div style={{ marginLeft: "50%", marginTop: "10px" }}>
          <button>Submit</button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

// import { useTheme } from '@mui/material/styles';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import {
//     TextField,
//     InputLabel,
//     Paper,
//     Grid,
//     Box,
//     Typography,
// } from '@mui/material';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

// const names = [
//     'Oliver Hansen',
//     'Van Henry',
//     'April Tucker',
//     'Ralph Hubbard',
//     'Omar Alexander',
//     'Carlos Abbott',
//     'Miriam Wagner',
//     'Bradley Wilkerson',
//     'Virginia Andrews',
//     'Kelly Snyder',
// ];

// function getStyles(name, personName, theme) {
//     return {
//         fontWeight:
//             personName.indexOf(name) === -1
//                 ? theme.typography.fontWeightRegular
//                 : theme.typography.fontWeightMedium,
//     };
// }

// function MultipleSelectPlaceholder({ onChange }) {
//     const theme = useTheme();
//     const [personName, setPersonName] = React.useState([]);

//     const handleChange = (event) => {
//         const {
//             target: { value },
//         } = event;

//         console.log(value, "Selected values");
//         setPersonName(
//             typeof value === 'string' ? value.split(',') : value,
//         );
//         onChange(value);
//     };

//     return (
//         <div>
//             <FormControl sx={{ m: 0, width: 461, mt: 2 }}>
//                 <Select
//                     multiple
//                     displayEmpty
//                     value={personName}
//                     onChange={handleChange}
//                     input={<OutlinedInput />}
//                     renderValue={(selected) => {
//                         if (selected.length === 0) {
//                             return <em>Select</em>;
//                         }

//                         return selected.join(', ');
//                     }}
//                     MenuProps={MenuProps}
//                     inputProps={{ 'aria-label': 'Without label' }}
//                 >
//                     <MenuItem disabled value="">
//                         <em>Select</em>
//                     </MenuItem>
//                     {names.map((name) => (
//                         <MenuItem
//                             key={name}
//                             value={name}
//                             style={getStyles(name, personName, theme)}
//                         >
//                             {name}
//                         </MenuItem>
//                     ))}
//                 </Select>
//             </FormControl>
//         </div>
//     );
// }

// import { useAutocomplete } from '@mui/base/useAutocomplete';
// import { styled } from '@mui/system';

// const Label = styled('label')({
//   display: 'block',
// });

// const Input = styled('input')(({ theme }) => ({
//   width: 455,
//   height: 54,
//   // backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
//   // color: theme.palette.mode === 'light' ? '#000' : '#fff',
// }));

// const Listbox = styled('ul')(({ theme }) => ({
//   width: 455,
//   margin: 0,
//   padding: 0,
//   zIndex: 1,
//   position: 'absolute',
//   listStyle: 'none',
//   backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
//   overflow: 'auto',
//   maxHeight: 200,
//   border: '1px solid rgba(0,0,0,.25)',
//   '& li.Mui-focused': {
//     backgroundColor: '#4a8df6',
//     color: 'white',
//     cursor: 'pointer',
//   },
//   '& li:active': {
//     backgroundColor: '#2977f5',
//     color: 'white',
//   },
// }));

// function UseAutocomplete({ onChange }) {

//   const {
//     getRootProps,
//     getInputLabelProps,
//     getInputProps,
//     getListboxProps,
//     getOptionProps,
//     inputValue,
//     groupedOptions,
//   } = useAutocomplete({
//     id: 'use-autocomplete-demo',
//     options: pageList,
//     getOptionLabel: (option) => option.title,
//   });

//   const handleChange = (selectedOption) => {
//     onChange(selectedOption ? selectedOption.title : null);
//     console.log(selectedOption.title, "selectedOption.title")
//   };

//   return (
//     <div>
//       <div {...getRootProps()}>
//         <Label {...getInputLabelProps()}></Label>
//         <Input
//           {...getInputProps()}
//           // Set the value of the input field using inputValue
//           value={inputValue}
//         />
//       </div>
//       {groupedOptions.length > 0 ? (
//         <Listbox {...getListboxProps()} >
//           {groupedOptions.map((option, index) => (
//             <li {...getOptionProps({ option, index })} onMouseDown={() => handleChange(option)}>
//               {option.title}
//             </li>
//           ))}
//         </Listbox>
//       ) : null}
//     </div>
//   );
// }

// // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
// const pageList = [
//   { title: 'The Shawshank Redemption', year: 1994 },
//   { title: 'The Godfather', year: 1972 },
//   { title: 'The Godfather: Part II', year: 1974 },
//   { title: 'The Dark Knight', year: 2008 },
//   { title: '12 Angry Men', year: 1957 },
//   { title: "Schindler's List", year: 1993 },
//   { title: 'Pulp Fiction', year: 1994 },
//   {
//     title: 'The Lord of the Rings: The Return of the King',
//     year: 2003,
//   },
//   { title: 'The Good, the Bad and the Ugly', year: 1966 },
//   { title: 'Fight Club', year: 1999 },
//   {
//     title: 'The Lord of the Rings: The Fellowship of the Ring',
//     year: 2001,
//   },
//   {
//     title: 'Star Wars: Episode V - The Empire Strikes Back',
//     year: 1980,
//   },
//   { title: 'Forrest Gump', year: 1994 },
//   { title: 'Inception', year: 2010 },
//   {
//     title: 'The Lord of the Rings: The Two Towers',
//     year: 2002,
//   },
//   { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
//   { title: 'Goodfellas', year: 1990 },
//   { title: 'The Matrix', year: 1999 },
//   { title: 'Seven Samurai', year: 1954 },
//   {
//     title: 'Star Wars: Episode IV - A New Hope',
//     year: 1977,
//   },
//   { title: 'City of God', year: 2002 },
//   { title: 'Se7en', year: 1995 },
//   { title: 'The Silence of the Lambs', year: 1991 },
//   { title: "It's a Wonderful Life", year: 1946 },
//   { title: 'Life Is Beautiful', year: 1997 },
//   { title: 'The Usual Suspects', year: 1995 },
//   { title: 'Léon: The Professional', year: 1994 },
//   { title: 'Spirited Away', year: 2001 },
//   { title: 'Saving Private Ryan', year: 1998 },
//   { title: 'Once Upon a Time in the West', year: 1968 },
//   { title: 'American History X', year: 1998 },
//   { title: 'Interstellar', year: 2014 },
//   { title: 'Casablanca', year: 1942 },
//   { title: 'City Lights', year: 1931 },
//   { title: 'Psycho', year: 1960 },
//   { title: 'The Green Mile', year: 1999 },
//   { title: 'The Intouchables', year: 2011 },
//   { title: 'Modern Times', year: 1936 },
//   { title: 'Raiders of the Lost Ark', year: 1981 },
//   { title: 'Rear Window', year: 1954 },
//   { title: 'The Pianist', year: 2002 },
//   { title: 'The Departed', year: 2006 },
//   { title: 'Terminator 2: Judgment Day', year: 1991 },
//   { title: 'Back to the Future', year: 1985 },
//   { title: 'Whiplash', year: 2014 },
//   { title: 'Gladiator', year: 2000 },
//   { title: 'Memento', year: 2000 },
//   { title: 'The Prestige', year: 2006 },
//   { title: 'The Lion King', year: 1994 },
//   { title: 'Apocalypse Now', year: 1979 },
//   { title: 'Alien', year: 1979 },
//   { title: 'Sunset Boulevard', year: 1950 },
//   {
//     title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
//     year: 1964,
//   },
//   { title: 'The Great Dictator', year: 1940 },
//   { title: 'Cinema Paradiso', year: 1988 },
//   { title: 'The Lives of Others', year: 2006 },
//   { title: 'Grave of the Fireflies', year: 1988 },
//   { title: 'Paths of Glory', year: 1957 },
//   { title: 'Django Unchained', year: 2012 },
//   { title: 'The Shining', year: 1980 },
//   { title: 'WALL·E', year: 2008 },
//   { title: 'American Beauty', year: 1999 },
//   { title: 'The Dark Knight Rises', year: 2012 },
//   { title: 'Princess Mononoke', year: 1997 },
//   { title: 'Aliens', year: 1986 },
//   { title: 'Oldboy', year: 2003 },
//   { title: 'Once Upon a Time in America', year: 1984 },
//   { title: 'Witness for the Prosecution', year: 1957 },
//   { title: 'Das Boot', year: 1981 },
//   { title: 'Citizen Kane', year: 1941 },
//   { title: 'North by Northwest', year: 1959 },
//   { title: 'Vertigo', year: 1958 },
//   {
//     title: 'Star Wars: Episode VI - Return of the Jedi',
//     year: 1983,
//   },
//   { title: 'Reservoir Dogs', year: 1992 },
//   { title: 'Braveheart', year: 1995 },
//   { title: 'M', year: 1931 },
//   { title: 'Requiem for a Dream', year: 2000 },
//   { title: 'Amélie', year: 2001 },
//   { title: 'A Clockwork Orange', year: 1971 },
//   { title: 'Like Stars on Earth', year: 2007 },
//   { title: 'Taxi Driver', year: 1976 },
//   { title: 'Lawrence of Arabia', year: 1962 },
//   { title: 'Double Indemnity', year: 1944 },
//   {
//     title: 'Eternal Sunshine of the Spotless Mind',
//     year: 2004,
//   },
//   { title: 'Amadeus', year: 1984 },
//   { title: 'To Kill a Mockingbird', year: 1962 },
//   { title: 'Toy Story 3', year: 2010 },
//   { title: 'Logan', year: 2017 },
//   { title: 'Full Metal Jacket', year: 1987 },
//   { title: 'Dangal', year: 2016 },
//   { title: 'The Sting', year: 1973 },
//   { title: '2001: A Space Odyssey', year: 1968 },
//   { title: "Singin' in the Rain", year: 1952 },
//   { title: 'Toy Story', year: 1995 },
//   { title: 'Bicycle Thieves', year: 1948 },
//   { title: 'The Kid', year: 1921 },
//   { title: 'Inglourious Basterds', year: 2009 },
//   { title: 'Snatch', year: 2000 },
//   { title: '3 Idiots', year: 2009 },
//   { title: 'Monty Python and the Holy Grail', year: 1975 },
// ];

// import TextField from '@mui/material/TextField';
