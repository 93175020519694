import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllSeoDetails, getSelectedSeoDetails, createSeoDetails, updateSeoDetails, deleteSeoDetails } from "../api/addSeoapi"

export const getSeoDetails = createAsyncThunk('/seo/get', async () => {
    try {
        const response = await getAllSeoDetails();
        return response.data;
    } catch (error) {
        console.log(error, "err");
        throw error
    }
})

export const getSelectedSeoDetail = createAsyncThunk('/seo/gett', async (formdata) => {
    try {
        const response = await getSelectedSeoDetails(formdata);
        return response.data;
    } catch (error) {
        console.log(error, "err");
        throw error
    }
})

export const createSeo = createAsyncThunk('/seo/create', async (formdata) => {
    try {
        const response = await createSeoDetails(formdata);
        console.log(response,"resp in thunk");
        return response.data;
    } catch (error) {
        console.log(error, "err");
        throw error
    }
})


export const updateSeo = createAsyncThunk('/seo/update', async (formdata) => {
    try {
        const response = await updateSeoDetails(formdata);
        return response.data;
    } catch (error) {
        console.log(error, "err");
        throw error;
    }
})


export const deleteSeo = createAsyncThunk('/seo/delete', async (formdata, { rejectWithValue }) => {
    try {
        console.log(formdata, "slice")
        const response = await deleteSeoDetails(formdata);
        return response.data;

    } catch (error) {
        console.log(error, "err");
        throw error

    }
})

const seoSlice = createSlice({
    name: "seoSlice",
    initialState: {
        testTable: null, message: "", testSeo: null
    },
    extraReducers: (builder) => {

        builder.addCase(getSeoDetails.fulfilled, (state, action) => {
            state.testTable = action.payload;
            console.log(action.payload, "qwertyui")
        })
        builder.addCase(getSeoDetails.rejected, (state) => {
            state.testTable = null;
        })

        builder.addCase(getSelectedSeoDetail.fulfilled, (state, action) => {
            state.testSeo = action.payload;
            console.log(action.payload, "qwertyui")
        })
        builder.addCase(getSelectedSeoDetail.rejected, (state) => {
            state.testSeo = null;
        })

        builder.addCase(createSeo.fulfilled, (state, action) => {
            state.message = action.payload.message;
        })
        builder.addCase(createSeo.rejected, (state, action) => {
            state.message = action.payload.message;
        })

        builder.addCase(updateSeo.fulfilled, (state, action) => {
            state.message = action.payload.message;
        })
        builder.addCase(updateSeo.rejected, (state) => {
            state.message = "";
        })

        builder.addCase(deleteSeo.fulfilled, (state, action) => {
            state.message = action.payload.message;
        })
        builder.addCase(deleteSeo.rejected, (state) => {
            state.message = "";
        });
    },
})

export default seoSlice.reducer;