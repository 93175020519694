import React from "react";
import { DataGrid } from "@mui/x-data-grid";

export default function AllUserDatatables({ rows }) {
  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: "id", headerName: "Sl", width: 70 },
    { field: "uid", headerName: "Uid", width: 130 },
    { field: "date", headerName: "Date", width: 130 },
    { field: "name", headerName: "Name", width: 130 },
    { field: "Subscribed", headerName: "Subscribed", width: 130 },
    { field: "email", headerName: "Email", width: 230 },
    {
      field: "profile",
      headerName: "Images",
      width: 130,
      renderCell: (params) => {
        return (
          params.row.profile && (
            <div className="userListUser">
              <img
                style={{ width: "30px", height: "30px", borderRadius: "5px" }}
                src={params.row.profile}
                alt=""
              />
            </div>
          )
        );
      },
    },
    { field: "gid", headerName: "Gid", width: 130 },
    { field: "active", headerName: "Active", width: 130 },
  ];

  return (
    <div
      style={{
        paddingTop: "3%",
        display: "flex",
        gap: "20%",
        textAlign: "center",
      }}
    >
      <div
        className="left"
        style={{
          flex: 1,
          marginLeft: "18%",
          overflowX: "scroll",
          marginRight: "15px",
        }}
      >
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 50 },
              },
            }}
            pageSizeOptions={[50, 100]}
            checkboxSelection
          />
        </div>
      </div>
    </div>
  );
}
