import React, { useEffect, useState } from 'react';
import { Paper, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getContacts, updateContactUs } from '../../redux/Slices/contactSlice';
import Datatable from '../mui-datatable/Datatable';
import { toast } from "react-toastify";

const Contact = () => {
  const auth = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const contactData = useSelector((state) => state.contact.contactTable?.data);
  const [page, pagechange] = useState(0);
  const [rowsPerPage, rowsPerPageChange] = useState(50);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (auth) {
          dispatch(getContacts());
        }
      } catch (error) {
        console.error("Error fetching extensions:", error);
      }
    };

    fetchData();
  }, [auth, dispatch]);

  const handleToggleResolve = (contactRow) => {
    const resolved = contactRow[6] === 1 ? 0 : 1;
    dispatch(updateContactUs({ id: contactRow[0], resolved }))
      .then((data) => {
        console.log(data.payload?.code == 200, "data in updatedcontact");
        if (data.payload?.code == 200) {
          toast.success("Updated Successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else {
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
        dispatch(getContacts());
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  const columns = contactData ? Object.keys(contactData[0]).map(key => {
    if (key === 'resolved') {
      return {
        name: key,
        label: 'Resolved',
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Button
                onClick={() => handleToggleResolve(tableMeta.rowData)}
                variant="contained"
                color={value ? 'success' : 'error'}
              >
                {value ? 'Resolved' : 'Pending'}
              </Button>
            );
          }
        }
      };
    }
    return { name: key, label: key.charAt(0).toUpperCase() + key.slice(1) };
  }) : [];

  const options = {
    selectableRows: 'none',
    filter: true,
    print: false,
    download: false,
    pagination: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [50, 100, 150],
    page: page,
    onChangePage: page => pagechange(page),
    onChangeRowsPerPage: value => {
      rowsPerPageChange(value);
      pagechange(0);
    },
  };

  return (
    <Paper sx={{ width: '80%', float: 'right', overflow: 'auto', marginTop: '8%', marginRight: '23px' }}>
      <Datatable
        title="Queries"
        data={contactData}
        columns={columns}
        options={options}
      />
    </Paper>
  );
};
export default Contact;
