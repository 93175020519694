import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Datatable from "../mui-datatable/Datatable";
import {
  dailyLoginUsers,
  setdailyLoginSignupUsers,
} from "../../redux/Slices/graphSlice";

const DailyLoginSignup = () => {
  const auth = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const useStats = useSelector((state) => state.graphs?.dailyLoginSignupUsers);
  const [page, pagechange] = useState(0);
  const [rowsPerPage, rowsPerPageChange] = useState(50);

  const helper = (ki) => {
    const today = new Date();
    if (ki === "yesterday") {
      today.setDate(today.getDate() - 1);
    } else if (ki === "oneWeekStart") {
      today.setDate(today.getDate() - 8);
    }
    const year = today.getFullYear().toString().slice(-2);
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so +1 is needed
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const defaultStartDate = helper("oneWeekStart");
  const defaultEndDate = helper("yesterday");
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  useEffect(() => {
    const fetchData = async () => {
      const formdata = {
        startDate: startDate,
        endDate: endDate,
      };
      try {
        if (auth) {
          dispatch(dailyLoginUsers(formdata)).then((data) => {
            if (data.payload.code === 200) {
              dispatch(setdailyLoginSignupUsers(data.payload.userResults));
            }
            console.log(data, "datadata");
          });
        }
      } catch (error) {
        console.error("Error fetching details:", error);
      }
    };

    fetchData();
  }, [auth, dispatch]);

  const columns = [
    { name: "uid", label: "User ID" },
    { name: "date", label: "date" },
    { name: "event_type", label: "Event Type" },
    { name: "name", label: "Name" },
    { name: "email", label: "Email" },
    {
      name: "isEmailVerified",
      label: "Email Verified",
      options: {
        customBodyRender: (value) => (value === 0 ? "No" : "Yes"),
      },
    },
  ];
  console.log(useStats, "useStatsuseStats");

  const options = {
    selectableRows: "none",
    filter: true,
    print: false,
    download: false,
    pagination: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [50, 100, 150],
    page: page,
    onChangePage: (page) => pagechange(page),
    onChangeRowsPerPage: (value) => {
      rowsPerPageChange(value);
      pagechange(0);
    },
  };

  return (
    <Paper
      sx={{
        width: "80%",
        float: "right",
        overflow: "auto",
        marginTop: "8%",
        marginRight: "23px",
      }}
    >
      <Datatable
        title="Daily Login/signup"
        data={useStats}
        columns={columns}
        options={options}
      />
    </Paper>
  );
};

export default DailyLoginSignup;
