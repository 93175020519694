import { useRef, useEffect, useState } from "react";
import { Stack, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { dailyLoginUsers } from "../../../redux/Slices/graphSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "chart.js/auto";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

import { Line } from "react-chartjs-2";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const DailyLoginUsers = () => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Line Chart",
      },
    },
  };

  const ref = useRef();
  const auth = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const DailyLoginUsersData = useSelector(
    (state) => state.graphs?.graphDailyLoginUsers
  );

  const today = new Date(); // Gets today's date
  today.setDate(today.getDate() - 7); // Sets the date to the previous day
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so +1 is needed
  const day = String(today.getDate()).padStart(2, "0");
  const yesterday = `${year}-${month}-${day}`;
  let today_date = new Date().toISOString().slice(0, 10);
  const [startDate, setStartDate] = useState(dayjs(yesterday) || null);
  const [endDate, setEndDate] = useState(dayjs(today_date) || null);
  useEffect(() => {
    const fetchData = async () => {
      // console.log(today, "today-----------");
      const formdata = {
        startDate: startDate,
        endDate: endDate,
      };
      // setStartDate(yesterday);
      // setEndDate(new Date().toISOString().slice(0, 10));
      try {
        if (auth) {
          dispatch(dailyLoginUsers(formdata)).then((data) =>
            console.log(data, "data")
          );
        }
      } catch (error) {
        console.log("error while fetching dailyLoginUsers", error);
      }
    };
    fetchData();
  }, [auth, dispatch, startDate, endDate]);

  const generateDailyLoginData = (data) => {
    return {
      labels: data.map((item) => item.date),
      datasets: [
        {
          label: "Number of Daily Login Users",
          data: data.map((item) => item.user_login),
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
        },
      ],
    };
  };

  const lineChartData = DailyLoginUsersData
    ? generateDailyLoginData(DailyLoginUsersData?.data)
    : null;
  const labels = lineChartData?.labels;

  const data = {
    labels,
    datasets: [
      {
        label: "Login Users",
        data: lineChartData?.datasets?.[0]?.data,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };

  return (
    <>
      <Stack sx={{ backgroundColor: "" }}>
        <Typography variant="h4" sx={{ textAlign: "center", padding: "30px" }}>
          Daily Login Users
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="datePicker">
            <DatePicker
              sx={{
                margin: "20px",
              }}
              label="Start Date"
              value={startDate}
              onChange={(newvalue) =>
                setStartDate(dayjs(newvalue.$d).format("YYYY-MM-DD"))
              }
              inputFormat="yyyy/mm/dd"
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              sx={{
                margin: "20px",
              }}
              label="End Date"
              value={endDate}
              onChange={(newvalue) =>
                setEndDate(dayjs(newvalue.$d).format("YYYY-MM-DD"))
              }
              inputFormat="yyyy/mm/dd"
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </LocalizationProvider>

        {lineChartData ? (
          <Line ref={ref} options={options} data={data} />
        ) : (
          <Typography
            variant="h5"
            sx={{ textAlign: "center", padding: "30px" }}
          >
            No Data Available
          </Typography>
        )}
      </Stack>
    </>
  );
};

export default DailyLoginUsers;
