import axios from "axios";
import AppConstatnt from "../../constants/AppConstant";

const API = axios.create({ baseURL: `${AppConstatnt.baseURL}` });

export const getUnsubscribedUsers = () => API.get("/users/unSubscribedUsers");
export const getSubscribedUsers = () => API.get("/users/paidUsers");



