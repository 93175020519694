import "./App.css";
// import SideNav from './components/Home/SideNav';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoutes from "./components/routes/PrivateRoutes";
import Profile from "./components/Profile/profile";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
// import Page1 from "./components/Pages/Page1";
import SideNav from "./components/Home/SideNav";
import SignUp from "./components/Signup/SignUp";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getProfile, login, setAdminRole } from "./redux/Slices/authSlice";
import { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginSignupRoutes from "./components/routes/LoginSignupRoutes";
import AllExtensions from "./components/Extensions/AllExtensions";
import Contact from "./components/Contact/Contact";
import AddDetails from "./components/SeoAdd/AddDetails";
import UpdateDetails from "./components/SeoAdd/UpdateDetails";
import AllDetails from "./components/SeoAdd/AllDetails";
import UnsubscribedUsers from "./components/All Users/UnsubscribedUsers";
import DailyLoginSignup from "./components/All Users/dailyLoginSignup";
import SubscribedUsers from "./components/All Users/SubscribedUsers";
import AllUsers from "./components/All Users/allUsers";
import ExtensionsByCat from "./components/Extensions/ExtensionsByCat";
import DisplayfaqTables from "./components/Faq/DisplayfaqTables";
import AllTestimonial from "./components/Testimonials/AllTestimonial";
import AddTestimonial from "./components/Testimonials/AddTestimonial";
import RecentlyUpdated from "./components/Extensions/ExtensionsStats/RecentlyUpdated";
import RecentlyAdded from "./components/Extensions/ExtensionsStats/RecentlyAdded";
import RecentlyRemoved from "./components/Extensions/ExtensionsStats/RecentlyRemoved";
import MostDownloaded from "./components/Extensions/ExtensionsStats/MostDownloaded";
import ManifestExtension from "./components/Extensions/ManifestExtension";
import Blogindex from "./components/blog/Blogindex";
import Blogupdate from "./components/blog/Blogupdate";
import Gallery from "./components/Gallery/Gallery";
import AddImage from "./components/Gallery/AddImage";
import ShowImages from "./components/Gallery/ShowImages";

import Loading from "./components/Home/Loading";

export default function App() {
  const dispatch = useDispatch();

  const userState = useSelector((state) => state.user);

  const authenticated = userState.isAuthenticated;
  const [isLoading, setIsLoading] = useState(true);

  const profileFetcher = useCallback(async () => {
    try {
      const data = await dispatch(getProfile());
      console.log(data);
      if (data && data.payload && data.payload.data) {
        dispatch(login(data.payload.data));
      }
    } catch (error) {
      console.error("Error fetching profile:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (userState.user === null) {
      profileFetcher().then(() => {
        setIsLoading(false);
      });
    } else {
      setIsLoading(true);
    }
  }, [userState, profileFetcher]);

  if (isLoading) {
    console.log("loading...")
    return <Loading />;
  }

  console.log(userState, "userState in app.js");
  console.log(userState.isAuthenticated, "Authenticted in app.js");

  return (
    <>
      <Router>
        {authenticated ? <SideNav /> : ''}
        <Routes>
          <Route element={<PrivateRoutes />}>
            {/* <Route path="/" element={<SideNav />}></Route> */}
            <Route path="/" element={<Dashboard />} />
            <Route path="/allextension" element={<AllExtensions />} />
            <Route path="/recently-added-extension" element={<RecentlyAdded />} />
            <Route path="/recently-updated-extension" element={<RecentlyUpdated />} />
            <Route path="/recently-removed-extension" element={<RecentlyRemoved />} />
            <Route path="/unsubscribedUsers" element={<UnsubscribedUsers />} />
            <Route path="/dailyloginsignup" element={<DailyLoginSignup />} />
            <Route path='/profile' element={<Profile />} />
            <Route path="/blog" element={<Blogindex />} />
            <Route path="/blog/:id" element={<Blogupdate />} />
            {/* <Route path="/gallery" element={<Gallery />} /> */}
            <Route path="/gallery/add-image" element={<AddImage />} />
            <Route path="/gallery/show-image" element={<ShowImages />} />
            <Route path="/seo" element={<AllDetails />} />
            <Route path="/seo/:id" element={<UpdateDetails />} />
            <Route path="/subscribedUsers" element={<SubscribedUsers />} />
            <Route path="/allUsers" element={<AllUsers />} />
            <Route path="/extbycat" element={<ExtensionsByCat />} />
            <Route path='/faq' element={<DisplayfaqTables />} />
            <Route path='/alltestimonials' element={<AllTestimonial />} />
            <Route path='/mostdownloadedExt' element={<MostDownloaded />} />
            <Route path='/manifestExt' element={<ManifestExtension />} />
            <Route path="/contact-details" element={<Contact />}></Route>

            {/* <Route path="/createTestimonial" element={<AddTestimonial/>}/> */}
          </Route>
          <Route element={<LoginSignupRoutes />}>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
          </Route>
          {/* <Route path="/" element={<Login/>}/> */}
        </Routes>
        <ToastContainer />
      </Router>
    </>
  );
}



