import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getAddedExts,
  getUpdatedExts,
  getRemovedExts,
  // getDailyUsers,
  getDailyLogin,
  getDailySignup,
} from "../api/graphapi";

export const addedExts = createAsyncThunk("added/graph", async () => {
  try {
    const response = await getAddedExts();
    console.log(response.data, "added");
    return response.data;
  } catch (error) {
    console.log(error.message, "myereeee");
    throw error; // You should rethrow the error to handle it in the component.
  }
});
export const updatedExts = createAsyncThunk("updated/graph", async () => {
  try {
    const response = await getUpdatedExts();
    console.log(response.data, "updated");
    return response.data;
  } catch (error) {
    console.log(error.message, "errorrrrr");
    throw error; // You should rethrow the error to handle it in the component.
  }
});
export const removedExts = createAsyncThunk("removed/graph", async () => {
  try {
    const response = await getRemovedExts();
    console.log(response.data, "removed");
    return response.data;
  } catch (error) {
    console.log(error.message, "errorrrrr");
    throw error; // You should rethrow the error to handle it in the component.
  }
});

// LINECHART
// export const dailyUsers = createAsyncThunk('dailyUsers/graph', async () => {
//     try {
//         const response = await getDailyUsers();
//         console.log(response.data, 'DailyUsers')
//         return response.data;
//     } catch (error) {
//         console.log(error.message, "myereeee");
//         throw error; // You should rethrow the error to handle it in the component.
//     }
// });

// DAILY LOGIN
export const dailyLoginUsers = createAsyncThunk(
  "dailyLogin/graph",
  async (formdata) => {
    try {
      // console.log(formdata, "formmmm")
      const response = await getDailyLogin(formdata);
      return response.data;
    } catch (error) {
      console.log(error.message, "daily login error");
      throw error; // You should rethrow the error to handle it in the component.
    }
  }
);
// DAILY SIGNUP
export const dailySignupUsers = createAsyncThunk(
  "dailySignup/graph",
  async (formData) => {
    try {
      console.log(formData, "SignupDate");
      const response = await getDailySignup(formData);
      console.log(response.data, "SIGNUP USERS");
      return response.data;
    } catch (error) {
      console.log(error.message, "myereeee");
      throw error; // You should rethrow the error to handle it in the component.
    }
  }
);

const extSlice = createSlice({
  name: "graphSlice",
  initialState: {
    graphAddedExts: null,
    graphUpdatedExts: null,
    graphRemovedExts: null,
    // graphDailyUsers: null,
    graphDailySignupUsers: null,
    graphDailyLoginUsers: null,
    dailyLoginSignupUsers: [],
  },
  reducers: {
    setdailyLoginSignupUsers: (state, action) => {
      state.dailyLoginSignupUsers = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addedExts.fulfilled, (state, action) => {
      state.graphAddedExts = action.payload;
    });
    builder.addCase(addedExts.rejected, (state, action) => {
      state.graphAddedExts = null;
    });
    builder.addCase(updatedExts.fulfilled, (state, action) => {
      state.graphUpdatedExts = action.payload;
    });
    builder.addCase(updatedExts.rejected, (state, action) => {
      state.graphUpdatedExts = null;
    });
    builder.addCase(removedExts.fulfilled, (state, action) => {
      state.graphRemovedExts = action.payload;
    });
    builder.addCase(removedExts.rejected, (state, action) => {
      state.graphRemovedExts = null;
    });
    builder.addCase(dailyLoginUsers.fulfilled, (state, action) => {
      state.graphDailyLoginUsers = action.payload;
    });
    builder.addCase(dailyLoginUsers.rejected, (state, action) => {
      state.graphDailyLoginUsers = null;
    });
    builder.addCase(dailySignupUsers.fulfilled, (state, action) => {
      state.graphDailySignupUsers = action.payload;
    });
    builder.addCase(dailySignupUsers.rejected, (state, action) => {
      state.graphDailySignupUsers = null;
    });
  },
});
export const { setdailyLoginSignupUsers } = extSlice.actions;

export default extSlice.reducer;
