import React, { useEffect, useState } from "react";
import { Paper, TextField } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useDispatch, useSelector } from "react-redux";
import { recenltyUpdatedExt } from "../../../redux/Slices/extSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs"; // Import the Dayjs library
import { toast } from "react-toastify";

const RecentlyUpdated = () => {
  const helper = (ki) => {
    const today = new Date();
    if (ki === "yesterday") {
      today.setDate(today.getDate() - 1);
    } else if (ki === "daybeforeyesterday") {
      today.setDate(today.getDate() - 2);
    }
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so +1 is needed
    const day = String(today.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };
  const auth = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const rectaddData = useSelector((state) => state.ext.tableUpdatedExt?.data);
  const [limit, setLimit] = useState(10);
  const [page, pagechange] = useState(0);
  const [rowsPerPage, rowsPerPageChange] = useState(50);
  const defaultStartDate = helper("daybeforeyesterday");
  const defaultEndDate = helper("yesterday");

  const [startDate, setStartDate] = useState(dayjs(defaultStartDate));

  const [endDate, setEndDate] = useState(dayjs(defaultEndDate));

  useEffect(() => {
    const fetchData = async () => {
      const formdata = {
        startDate: startDate,
        endDate: endDate,
        limit,
      };
      try {
        if (auth) {
          dispatch(recenltyUpdatedExt(formdata))
            .then((data) => {
              if (data.payload?.code == 200) {
                toast.success("Data loaded successfully!", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
              } else {
                toast.error("Something went wrong", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
              }
            })
            .catch((err) => {
              console.log(err, "err in recent removed ext");
              toast.error("Something went wrong", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
              });
            });
        }
      } catch (error) {
        console.error("Error fetching extensions:", error);
      }
    };

    fetchData();
  }, [auth, dispatch, startDate, endDate, limit]);

  const columns = rectaddData ? Object.keys(rectaddData[0]) : [];

  const options = {
    selectableRows: "none",
    filter: true,
    print: false,
    download: false,
    pagination: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [50, 100, 150],
    page: page,
    onChangePage: (newPage) => {
      // console.log(page, "page in onchangepage");
      pagechange(newPage);
      if (newPage === Math.ceil(rectaddData.length / rowsPerPage) - 1) {
        setLimit((prev) => prev + 15);
      }

      // pagechange({ ...page, current: page, prev: page - 1 });
    },
    onChangeRowsPerPage: (value) => {
      rowsPerPageChange(value);
      if (value > rectaddData.length) {
        setLimit((prev) => prev + 15);
      }
      pagechange(0);
    },
  };

  return (
    <Paper
      sx={{
        width: "80%",
        float: "right",
        overflow: "auto",
        marginTop: "8%",
        marginRight: "23px",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="datePicker">
          <DatePicker
            sx={{
              margin: "20px",
            }}
            label="Start Date"
            value={startDate}
            onChange={(newvalue) =>
              setStartDate(dayjs(newvalue.$d).format("YYYY-MM-DD"))
            }
            inputFormat="yyyy/mm/dd"
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            sx={{
              margin: "20px",
            }}
            label="End Date"
            value={endDate}
            onChange={(newvalue) =>
              setEndDate(dayjs(newvalue.$d).format("YYYY-MM-DD"))
            }
            inputFormat="yyyy/mm/dd"
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
      </LocalizationProvider>

      <MUIDataTable
        title="Recently Updated Extension"
        data={rectaddData}
        columns={columns}
        options={options}
      />
    </Paper>
  );
};

export default RecentlyUpdated;
