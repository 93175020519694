import { useState } from "react";
import {
  AppBar,
  Toolbar,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import logo from "./CRXLOGO.png";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import { logout, logoutuser } from "../../redux/Slices/authSlice";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import HomeIcon from "@mui/icons-material/Home";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import PersonIcon from "@mui/icons-material/Person";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import CategoryIcon from "@mui/icons-material/Category";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import FeedIcon from '@mui/icons-material/Feed';
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ReviewsIcon from "@mui/icons-material/Reviews";
import { useDispatch } from "react-redux";
import Dashboard from "../Dashboard/Dashboard";

function NavScrollExample() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(false);

  const [openGraphs, setOpenGraphs] = useState(false);
  const [userData, setUserData] = useState(false);
  const [extensionStats, setExtensionStats] = useState(false);
  const [gallaryStats, setGallaryStats] = useState(false);
  const [testimonials, setTestimonials] = useState(false);

  const toggleState = (state, setState) => {
    setState(!state);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    dispatch(logoutuser()).then((data) => {
      if (data.payload.code === 200) {
        console.log(data);
        navigate("/login");
      }
    });
  };

  const marginn = {
    marginLeft: "25px",
  };

  return (
    <>
      <AppBar expand="lg" color="" position="fixed">
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton size="large" edge="start">
            <img src={logo} alt="CRXLOGO" className="logo" />
          </IconButton>
          <div>
            <IconButton
              aria-controls="avatar-menu"
              aria-haspopup="true"
              onClick={handleClick}
              color="inherit"
            >
              <Avatar>
                <AccountCircleIcon />
              </Avatar>
            </IconButton>
            <Menu
              id="avatar-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem sx={{ width: "200px", justifyContent: "center" }} >
                <PersonOutlineIcon />
                <Link style={{ textDecoration: 'none', color: 'black' }} to={'/profile'}>&nbsp;&nbsp;My Profile</Link>
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={handleClose}
                sx={{ width: "200px", justifyContent: "center" }}
              >
                <LogoutIcon />
                &nbsp;&nbsp;Logout
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>

      <List
        sx={{
          width: "17%",
          height: "90vh",
          marginTop: "4%",
          boxShadow: "15px 10px 22px 3px rgba(0,0,0,0.1)",
          position: "fixed",
          zIndex: 8,
          overflow: "auto",
        }}
      >
        <ListItemButton divider onClick={() => navigate("/")}>
          <PersonIcon sx={{ color: "gray", padding: "10px" }} />
          <ListItemText
            primary="Dashboard"
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
          />
        </ListItemButton>

        {/* PRICING */}
        <ListItemButton divider onClick={() => navigate("/profile")}>
          <PersonIcon sx={{ color: "gray", padding: "10px" }} />
          <ListItemText
            primary="Profile"
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
          />
        </ListItemButton>

        <ListItemButton divider onClick={() => navigate("/seo")}>
          <PersonIcon sx={{ color: "gray", padding: "10px" }} />
          <ListItemText
            primary="Add Seo"
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
          />
        </ListItemButton>

        <ListItemButton divider onClick={() => navigate("/blog")}>
          <FeedIcon sx={{ color: "gray", padding: "10px" }} />
          <ListItemText
            primary="Blog"
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
          />
        </ListItemButton>

        {/* <ListItemButton divider onClick={() => navigate("/gallery/add-image")}>
          <FeedIcon sx={{ color: "gray", padding: "10px" }} />
          <ListItemText
            primary="Gallery"
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
          />
        </ListItemButton> */}

        {/* <Route path="/gallery/add-image" element={<AddImage />} />
            <Route path="/gallery/show-image" element={<ShowImages />} /> */}


        {/* Gallery */}
        <ListItemButton
          onClick={() => toggleState(gallaryStats, setGallaryStats)}
          divider
        >
          <CategoryIcon sx={{ color: "gray", padding: "10px" }} />
          <ListItemText
            primary="Gallery"
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
          />
          {gallaryStats ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={gallaryStats} timeout="auto">
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => {
                navigate("/gallery/add-image");
              }}
            >
              <ArrowRightIcon
                sx={{ color: "gray", padding: "10px", marginLeft: "25px" }}
              />
              <ListItemText primary="Add Image" />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                navigate("/gallery/show-image");
              }}
            >
              <ArrowRightIcon
                sx={{ color: "gray", padding: "10px", marginLeft: "25px" }}
              />
              <ListItemText
                primary="Show Image"
              //
              />
            </ListItemButton>
          </List>
        </Collapse>


        {/* ALL USERS */}
        <ListItemButton
          onClick={() => toggleState(userData, setUserData)}
          divider
        >
          <PeopleOutlineIcon sx={{ color: "gray", padding: "10px" }} />
          <ListItemText
            primary="All Users"
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
          />
          {userData ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={userData} timeout="auto">
          <List component="div" disablePadding>
            <ListItemButton onClick={() => navigate("/unsubscribedUsers")}>
              <ArrowRightIcon
                sx={{ color: "gray", padding: "10px", marginLeft: "25px" }}
              />
              <ListItemText primary="Unsubscribed Users" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/subscribedUsers")}>
              <ArrowRightIcon
                sx={{ color: "gray", padding: "10px", marginLeft: "25px" }}
              />
              <ListItemText primary="Subscribed Users" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/allUsers")}>
              <ArrowRightIcon
                sx={{ color: "gray", padding: "10px", marginLeft: "25px" }}
              />
              <ListItemText primary="All Users" />
            </ListItemButton>
            <ListItemButton onClick={() => navigate("/dailyloginsignup")}>
              <ArrowRightIcon
                sx={{ color: "gray", padding: "10px", marginLeft: "25px" }}
              />
              <ListItemText primary="Daily login/signup" />
            </ListItemButton>
          </List>
        </Collapse>

        {/* CATEGORIES */}
        <ListItemButton onClick={() => navigate("/extbycat")} divider>
          <CategoryIcon sx={{ color: "gray", padding: "10px" }} />
          <ListItemText
            primary="Categories"
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
          />
        </ListItemButton>

        {/* EXTENSION STATS */}
        <ListItemButton
          onClick={() => toggleState(extensionStats, setExtensionStats)}
          divider
        >
          <CategoryIcon sx={{ color: "gray", padding: "10px" }} />
          <ListItemText
            primary="Extension Stats"
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
          />
          {extensionStats ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>
        <Collapse in={extensionStats} timeout="auto">
          <List component="div" disablePadding>
            <ListItemButton
              onClick={() => {
                navigate("/recently-added-extension");
              }}
            >
              <ArrowRightIcon
                sx={{ color: "gray", padding: "10px", marginLeft: "25px" }}
              />
              <ListItemText primary="Recently Added" />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                navigate("/recently-updated-extension");
              }}
            >
              <ArrowRightIcon
                sx={{ color: "gray", padding: "10px", marginLeft: "25px" }}
              />
              <ListItemText
                primary="Recently Updated"
              //
              />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                navigate("/recently-removed-extension");
              }}
            >
              <ArrowRightIcon
                sx={{ color: "gray", padding: "10px", marginLeft: "25px" }}
              />
              <ListItemText
                primary="Recently Removed"
              //
              />
            </ListItemButton>
            <ListItemButton
              onClick={() => {
                navigate("/mostdownloadedExt");
              }}
            >
              <ArrowRightIcon
                sx={{ color: "gray", padding: "10px", marginLeft: "25px" }}
              />
              <ListItemText
                primary="Most Downloaded"
              //
              />
            </ListItemButton>
          </List>
        </Collapse>

        {/* ALL EXTENSION DETAILS */}
        <ListItemButton divider onClick={() => navigate("/allextension")}>
          <LogoutIcon sx={{ color: "gray", padding: "10px" }} />
          <ListItemText
            primary="All Extensions Details"
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
          />
        </ListItemButton>
        <ListItemButton divider onClick={() => navigate("/manifestExt")}>
          <LogoutIcon sx={{ color: "gray", padding: "10px" }} />
          <ListItemText
            primary="Extensions by Versions"
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
          />
        </ListItemButton>

        {/* CONTACT FORM */}
        <ListItemButton divider onClick={() => navigate("/contact-details")}>
          <ImportContactsIcon sx={{ color: "gray", padding: "10px" }} />
          <ListItemText
            primary="Contact Form"
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
          />
        </ListItemButton>

        {/* TESTIMONIAL */}
        <ListItemButton onClick={() => navigate("/alltestimonials")} divider>
          <ReviewsIcon sx={{ color: "gray", padding: "10px" }} />
          <ListItemText
            primary="Testimonials"
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
          />
        </ListItemButton>
        <ListItemButton divider onClick={() => navigate("/faq")}>
          <HelpOutlineIcon sx={{ color: "gray", padding: "10px" }} />
          <ListItemText
            primary="FAQs"
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
          />
        </ListItemButton>

        {/* LOGOUT */}
        <ListItemButton divider onClick={handleClose}>
          <LogoutIcon sx={{ color: "gray", padding: "10px" }} />
          <ListItemText
            primary="Logout"
            primaryTypographyProps={{
              fontWeight: "bold",
            }}
          />
        </ListItemButton>
      </List>
      {/* <Dashboard/> */}
    </>
  );
}

export default NavScrollExample;


