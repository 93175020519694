import * as React from "react";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  InputLabel,
  Paper,
  useTheme,
  TextField,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import { updateSeo } from "../../redux/Slices/addSeoSlice";
import { getSelectedSeoDetail } from "../../redux/Slices/addSeoSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function UpdateDetails({ setUpdate }) {
  const theme = useTheme();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [personName, setPersonName] = React.useState([]);
  const [iD, setID] = useState(null);
  const [title, setTitle] = useState(null);
  const [description, setDesc] = useState(null);
  const [data, setData] = useState(null);
  const getSelectedDetail = useSelector((state) => state.seo.testSeo);
  console.log(id, "iddddddddddd");

  const notify = (value) =>
    toast.success(value, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const failure = (value) =>
    toast.error(value, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  useEffect(() => {
    const fetchData = async () => {
      console.log("the useeffect");
      if (id) {
        try {
          await dispatch(getSelectedSeoDetail(id));
          console.log("dispatch");
        } catch (error) {
          console.log("Error fetching SEO details:", error);
        }
      }
    };
    fetchData();
  }, [id]);

  useEffect(() => {
    if (getSelectedDetail != null && getSelectedDetail.data.length > 0) {
      const firstSeo = getSelectedDetail.data[0];
      setID(firstSeo.id);
      setTitle(firstSeo.seotitle);
      setDesc(firstSeo.seodesc);
      setData(firstSeo.page);
    }
  }, [getSelectedDetail]);

  function ComboBox({ onChange }) {
    return (
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={pageList}
        value={data}
        onChange={(event, newValue) => {
          onChange(newValue ? newValue.label : null);
        }}
        sx={{ width: "100%" }}
        renderInput={(params) => <TextField {...params} label="Select" />}
      />
    );
  }

  // Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
  const pageList = [
    { label: "/extension-stats" },
    { label: "/featured" },
    { label: "/most-downloaded" },
    { label: "/top-grossing" },
    { label: "/manifest-v2" },
    { label: "/manifest-v3" },
    { label: "/categories" },
    { label: "/recently-added" },
    { label: "/recently-updated" },
    { label: "/recently-removed" },
    { label: "/" },
    { label: "/about-us" },
    { label: "/contact-us" },
    { label: "/login" },
    { label: "/register" },
    { label: "/pricing" },
    { label: "/privacy-policy" },
    { label: "/search" },
    { label: "/terms-conditions" },
  ];

  // console.log(getSelectedDetail.data, "check");
  // console.log(title, "titlegetSelectedDetail");
  // console.log(id, "titlegetSelectedDetail");
  // console.log(description, "titlegetSelectedDetail");
  // console.log(data, "titlegetSelectedDetail");

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(title, "title");
    console.log(description, "desc");
    console.log(data, "data");
    const seodata = { id, title, description, data };
    dispatch(updateSeo(seodata))
      .then((response) => {
        console.log(response, "jhhjherlwjehr");
        if (response.payload.code == 201) {
          notify(response.payload.message);
          navigate("/seo");
        }
        if (response.payload.code == 400) {
          failure(response.payload.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div >
      <div
        style={{
          paddingTop: "5%",
          display: "flex",
          gap: "20%",
          textAlign: "center",
        }}
      >
        <h1 style={{ paddingLeft: "18%" }}>Make Changes</h1>
      </div>
      <form onSubmit={handleSubmit}>

        <div style={{
          display: "flex",
          gap: "20%",
          marginLeft: "10px",
          paddingLeft: "18%",
          justifyContent: 'space-around',
          marginBottom: '30px'
        }}>
          <div style={{ width: '100%' }}>
            Seo Title
          </div>
          <div style={{ width: '100%', marginRight: '15px' }}>
            <TextField
              value={title}
              fullWidth
              id="fullWidth"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
        </div>

        {/* <div style={{ display: "flex", gap: "20%", textAlign: "center" }}>
          <div
            className="left"
            style={{
              paddingTop: "20px",
              marginLeft: "10px",
              flex: 1,
              marginLeft: "10%",
            }}
          >
            Seo Title
          </div>
          <div className="right" style={{ flex: 1, marginRight: "2%" }}>
            <TextField
              value={title}
              fullWidth
              id="fullWidth"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
        </div> */}


        <div style={{
          display: "flex",
          gap: "20%",
          marginLeft: "10px",
          paddingLeft: "18%",
          justifyContent: 'space-around',
          marginBottom: '30px'
        }}>
          <div style={{ width: '100%' }}>
            Seo Desc
          </div>
          <div style={{ width: '100%', marginRight: '15px' }}>
            <TextField
              value={description}
              fullWidth
              id="fullWidth"
              onChange={(e) => {
                setDesc(e.target.value);
              }}
            />
          </div>
        </div>

        {/* <div
          style={{
            paddingTop: "10px",
            display: "flex",
            gap: "20%",
            textAlign: "center",
          }}
        >
          <div
            className="left"
            style={{
              paddingTop: "20px",
              marginLeft: "10px",
              flex: 1,
              marginLeft: "10%",
            }}
          >
            Seo Desc
          </div>
          <div className="right" style={{ flex: 1, marginRight: "2%" }}>
            <TextField
              value={description}
              fullWidth
              id="fullWidth"
              onChange={(e) => {
                setDesc(e.target.value);
              }}
            />
          </div>
        </div> */}

        <div style={{
          display: "flex",
          gap: "20%",
          marginLeft: "10px",
          paddingLeft: "18%",
          justifyContent: 'space-around',
          marginBottom: '30px'
        }}>
          <div style={{ width: '100%' }}>
            Seo Data
          </div>
          <div style={{ width: '100%', marginRight: '15px' }}>
            <ComboBox onChange={setData} />
          </div>
        </div>


        {/* <div
          style={{
            marginLeft: "3px",
            display: "flex",
            gap: "20%",
            textAlign: "center",
          }}
        >
          <div
            className="left"
            style={{
              paddingTop: "20px",
              marginLeft: "10px",
              flex: 1,
              marginLeft: "10%",
            }}
          >
            Seo Data
          </div>
          <div
            className="right"
            style={{ flex: 1, marginRight: "2%", paddingTop: "10px" }}
          >
           
            <ComboBox onChange={setData} />
          </div>
        </div> */}

        <div style={{ marginLeft: "50%", marginTop: "10px" }}>
          <button>Submit</button>
        </div>

      </form>
    </div>
  );
}
