import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { unSubscribedUsers } from "../../redux/Slices/usersStatsSlice";
import Datatable from "../mui-datatable/Datatable";

const UnsubscribedUsers = () => {
  const auth = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const useStats = useSelector((state) => state.stats.table?.data);
  const [page, pagechange] = useState(0);
  const [rowsPerPage, rowsPerPageChange] = useState(50);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (auth) {
          await dispatch(unSubscribedUsers());
        }
      } catch (error) {
        console.error("Error fetching details:", error);
      }
    };

    fetchData();
  }, [auth, dispatch]);

  const columns = useStats ? Object.keys(useStats[0]) : [];

  const options = {
    selectableRows: "none",
    filter: true,
    print: false,
    download: false,
    pagination: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [50, 100, 150],
    page: page,
    onChangePage: (page) => pagechange(page),
    onChangeRowsPerPage: (value) => {
      rowsPerPageChange(value);
      pagechange(0);
    },
  };

  return (
    <Paper
      sx={{
        width: "80%",
        float: "right",
        overflow: "auto",
        marginTop: "8%",
        marginRight: "23px",
      }}
    >
      <Datatable
        title="Unsubscribed Users"
        data={useStats}
        columns={columns}
        options={options}
      />
    </Paper>
  );
};

export default UnsubscribedUsers;
