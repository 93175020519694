import axios from "axios";
import AppConstatnt from "../../constants/AppConstant";

const API = axios.create({ baseURL: `${AppConstatnt.baseURL}` });

export const signup = (formData) => API.post("/authpanel/register", formData);
export const loginWithPassword = (formData) =>
  API.post("/authpanel/login", formData);
export const getProfiler = () =>
  API.get("/authpanel/profile", {
    withCredentials: true,
  });
export const logoutsess = () => API.get("/authpanel/logout");
