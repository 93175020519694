import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getExtByCat } from "../../redux/Slices/extSlice";
import Datatable from "../mui-datatable/Datatable";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";

const ExtensionsByCat = () => {
  const auth = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const extbycat = useSelector((state) => state.ext.tableExtbyCate?.data);
  const [category, setCategory] = useState("All");
  const [limit, setLimit] = useState(10);
  const [page, pagechange] = useState(0);
  const [rowsPerPage, rowsPerPageChange] = useState(50);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (auth) {
          dispatch(getExtByCat({ category: category, limit: limit }))
            .then((data) => {
              if (data.payload?.code == 200) {
                toast.success("Data loaded successfully!", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
              } else {
                toast.error("Something went wrong", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
              }
            })
            .catch((err) => {
              console.log(err, "err in recent removed ext");
              toast.error("Something went wrong", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
              });
            });
        }
      } catch (error) {
        console.error("Error fetching extensions:", error);
      }
    };

    fetchData();
  }, [auth, dispatch, category, limit]);

  const columns = extbycat ? Object.keys(extbycat[0]) : [];
  const options = {
    selectableRows: "none",
    filter: true,
    print: false,
    download: false,
    pagination: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [50, 100, 150],
    page: page,
    onChangePage: (newPage) => {
      // console.log(page, "page in onchangepage");
      pagechange(newPage);
      if (newPage === Math.ceil(extbycat.length / rowsPerPage) - 1) {
        setLimit((prev) => prev + 15);
      }

      // pagechange({ ...page, current: page, prev: page - 1 });
    },
    onChangeRowsPerPage: (value) => {
      rowsPerPageChange(value);
      if (value > extbycat.length) {
        setLimit((prev) => prev + 15);
      }
      pagechange(0);
    },
  };

  let catOptions = [
    { value: "All", label: "All" },
    { value: "Accessibility", label: "Accessibility" },
    { value: "Developer", label: "Developer Tools" },
    { value: "Workflow", label: "Workflow & Planning" },
    { value: "Fun", label: "Fun" },
    { value: "Shopping", label: "Shopping" },
    { value: "Social", label: "Social" },
    { value: "Privacy", label: "Privacy & Security" },
    { value: "Entertainment", label: "Entertainment" },
    { value: "Productivity", label: "Productivity" },
    { value: "Education", label: "Education" },
    { value: "other", label: "Other" },
    { value: "news", label: "News" },
    { value: "nature", label: "Nature" },
    { value: "minimalist", label: "Minimalist" },
    { value: "games", label: "Games" },
    { value: "colors", label: "Colors" },
    { value: "animals", label: "Animals" },
    { value: "artist", label: "Artist" },
    { value: "cars", label: "Cars" },
    { value: "functionality", label: "Functionality" },
    { value: "dark", label: "Dark" },
    { value: "sports", label: "Sports" },
    { value: "Apps", label: "Apps" },
  ];

  return (
    <>
      {" "}
      <Paper
        sx={{
          width: "80%",
          float: "right",
          overflow: "auto",
          marginTop: "8%",
          marginRight: "23px",
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label">Categories</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={category}
            label="Categories"
            onChange={(e) => setCategory(e.target.value)}
          >
            {catOptions.map((cat) => (
              <MenuItem value={cat?.value}>{cat?.label}</MenuItem>
            ))}
            {/* <MenuItem value="All">All</MenuItem>
            <MenuItem value="Category 1">Category 1</MenuItem>
            <MenuItem value="Category 2">Category 2</MenuItem> */}
            {/* <MenuItem value="Search tools">Search Tools</MenuItem>
        <MenuItem value={50}>Fun</MenuItem>
         <MenuItem value={60}>Photos</MenuItem> */}
          </Select>
        </FormControl>

        <Datatable
          title="All Extension Details"
          data={extbycat}
          columns={columns}
          options={options}
        />
      </Paper>
    </>
  );
};

export default ExtensionsByCat;
