import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getMostdownloadedExt } from "../../../redux/Slices/extSlice";
import Datatable from "../../mui-datatable/Datatable";

const MostDownloaded = () => {
  const auth = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const allext = useSelector((state) => state.ext.tableMostDownloadedExt?.data);
  const { total_most_downloaded_ext, loading } = useSelector(
    (state) => state.ext
  );
  // console.log(allext, "allextallext");
  const [limit, setLimit] = useState(50);
  const [page, pagechange] = useState(0);
  const [rowsPerPage, rowsPerPageChange] = useState(50);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (auth) {
          let data = { limit, page };

          dispatch(getMostdownloadedExt(data));
        }
      } catch (error) {
        console.error("Error fetching extensions:", error);
      }
    };

    fetchData();
  }, [auth, dispatch, page]);

  const columns = allext && allext.length > 0 ? Object.keys(allext[0]) : [];
  const totalPages = Math.ceil(total_most_downloaded_ext / rowsPerPage);

  const options = {
    selectableRows: "none",
    filter: true,
    print: false,
    download: false,
    pagination: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [50, 100, 150],
    page: page,
    count: total_most_downloaded_ext,
    onChangePage: (newPage) => {
      pagechange(newPage);
      if (newPage >= totalPages - 1) {
        setLimit(50);
        // setLimit((prevLimit) => prevLimit + 50);
        pagechange((prev) => prev + 1);
      }
    },
    onChangeRowsPerPage: (value) => {
      rowsPerPageChange(value);
      // pagechange(0);
    },
  };

  // console.log(allext, "allext");
  return (
    <Paper
      sx={{
        width: "80%",
        float: "right",
        overflow: "auto",
        marginTop: "8%",
        marginRight: "23px",
      }}
    >
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Datatable
          title="Most Downloaded"
          data={allext && allext.length > 0 ? allext : []}
          columns={columns}
          options={options}
        />
      )}
    </Paper>
  );
};

export default MostDownloaded;
