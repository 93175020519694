import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updatedExts } from '../../../redux/Slices/graphSlice'
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import { Stack, Typography } from '@mui/material';
import { Skeleton } from "antd";
import { DotChartOutlined } from '@ant-design/icons';
Chart.register(ArcElement, Tooltip, Legend);

const UpdatedExts = () => {
    const auth = useSelector((state) => state.user.isAuthenticated);
    const dispatch = useDispatch();
    const updatedExtsData = useSelector((state) => state.graphs?.graphUpdatedExts);
    console.log(updatedExtsData?.data, "UpdatedData")

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (auth) {
                    dispatch(updatedExts());
                }
            } catch (error) {
                console.log("Error fetching UpdatedData:", error);
            }
        };
        fetchData();
    }, [auth, dispatch]);

    const generatePieData = (data) => {
        return {
            labels: data.map((item) => item.category
            ),
            datasets: [
                {
                    label: 'Exts',
                    data: data.map((item) => item.total_ext),
                    backgroundColor: ['#ff6347', '#00b894', '#3498db', '#ffb6c1'],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                    ],
                    borderWidth: 2,
                    hoverBackgroundColor: ['#e74c3c', '#00a37a', '#2980b9', '#ff90a9']
                },
            ],
        };
    };

    const pieChartData = updatedExtsData ? generatePieData(updatedExtsData?.data) : null;

    return (
        <>
            <Stack >
                <Typography variant='h5' sx={{ textAlign: 'center' }}>
                    Updated Extensions
                </Typography>
                {
                    pieChartData ? (
                        <div style={{ width: '300px', height: '300px' }}>
                            {pieChartData && <Pie data={pieChartData} />}
                        </div>
                    ) : (
                        <Skeleton.Node style={{ width: '300px', height: '100%' }} active>
                            <DotChartOutlined style={{ fontSize: 80, color: '#bfbfbf' }} />
                        </Skeleton.Node>
                    )
                }

            </Stack>
        </>
    )
}

export default UpdatedExts
