import React, { useEffect, useState } from 'react';
import Allblogdetails from './Allblogdetails';
import BlogDatatable from './BlogDatatable';
import axios from 'axios';
import AppConstatnt from "../../constants/AppConstant";
let baseUrl = AppConstatnt.baseURL;
console.log(baseUrl, 'baseUrl');


export default function Blogindex() {

    const [blog, setBlogs] = useState([]);
    const [update, setUpdate] = useState(false);

    useEffect(() => {

        axios.get(`${baseUrl}` + '/seo/getblogs').then(({ data }) => {
            const formattedBlogs = data.data[0].map((blog, index) => ({
                id: index + 1,
                ext_id: blog.ext_id,
                meta_title: blog.meta_title,
                meta_description: blog.meta_description,
                title: blog.title,
                featured_img: blog.featured_img,
                author: blog.author,
                active: blog.active,
                similar_blogs: extractLabels(blog.similar_blogs),
                reading_time: blog.reading_time,
                html: blog.html
            }));
            setBlogs(formattedBlogs);

        })
    }, [update])

    let extractLabels = (similarBlogsString) => {
        try {
            const similarBlogsArray = JSON.parse(similarBlogsString);
            return similarBlogsArray.map(item => item.label);
        } catch (error) {
            console.error("Error parsing similar_blogs string:", error);
            return [];
        }
    };

    return (
        <div >
            <Allblogdetails setUpdate={setUpdate} />
            <BlogDatatable rows={blog} setUpdate={setUpdate} />
        </div>
    )
}




