import React, { useEffect, useState } from "react";

import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { getAllFaq, updateFaq } from "../../redux/Slices/faqSlice";
import { Paper, Typography, Divider } from "@mui/material";
import {
  GridRowModes,
  DataGrid,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import AddFaqs from "./AddFaqs";
import { toast } from "react-toastify";

export default function FullFeaturedCrudGrid() {
  const table = useSelector((state) => state.faq?.faqtable) || [];

  // console.log(table, "table");
  const dispatch = useDispatch();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [statusbtn, setStatusbtn] = useState(
    table.length > 0 ? table.map((table) => table.status) : []
  );
  useEffect(() => {
    setStatusbtn(
      table.map((table) => {
        return { status: table?.status, id: table?.faqs_id };
      })
    );
  }, [table, dispatch, table?.status]);
  // console.log(statusbtn, "statusbtn");
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (true) {
          dispatch(getAllFaq())
            .then((data) => {
              if (data.payload?.code == 200) {
                toast.success("Data loaded successfully!", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
              } else {
                toast.error("Something went wrong", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
              }
            })
            .catch((err) => {
              console.log(err, "err in recent removed ext");
              toast.error("Something went wrong", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
              });
            });
        }
      } catch (error) {
        console.error("Error fetching extensions:", error);
      }
    };

    fetchData();
  }, []);
  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };
  const getRowId = (row) => row.faqs_id;

  const handleStatusChange = (faqs_id, currentStatus) => () => {
    // console.log("toggle");
    const updatedStatus = currentStatus === 0 ? 1 : 0;
    let updatedStatusBtn = statusbtn?.map((btn) => {
      if (btn.id === faqs_id) {
        return { ...btn, status: btn.status === 1 ? 0 : 1 };
      } else {
        return btn;
      }
    });
    // console.log(updatedStatusBtn, "updatedStatusBtn");
    setStatusbtn(updatedStatusBtn);
    // setStatusbtn({...statusbtn,
    //   statusbtn.filter((statusbtn) => statusbtn.id === faqs_id)[0].status
    // });

    dispatch(updateFaq({ faqs_id, status: updatedStatus }))
      .then((data) => {
        console.log(data.payload?.code == 200, "data in updatedFaq");
        if (data.payload?.code == 200) {
          toast.success("Updated Successfully", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else {
          toast.error("Something went wrong", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
        dispatch(getAllFaq());
      })
      .catch((error) => {
        console.error("Error updating status:", error);
        toast.error("Something went wrong", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "question",
      headerName: "Question",
      type: "text",
      width: 300,
    },
    {
      field: "answer",
      headerName: "Answer",
      type: "string",
      width: 300,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "page",
      headerName: "Page",
      type: "number",
      width: 300,
    },

    {
      field: "status",
      headerName: "Status",
      type: "boolean",
      width: 300,
      renderCell: (params) => {
        const faqs_id = params.row.faqs_id;
        const currentStatus = params.row.status;
        const isInEditMode = rowModesModel[faqs_id]?.mode === GridRowModes.Edit;

        return (
          <Switch
            checked={
              statusbtn && statusbtn.length > 0
                ? statusbtn.filter((statusbtn) => statusbtn.id === faqs_id)[0]
                  ?.status === 1
                : currentStatus === 1
            }
            onChange={
              isInEditMode
                ? undefined
                : handleStatusChange(faqs_id, currentStatus)
            }
          />
        );
      },
    },
  ];

  return (
    <Paper
      sx={{
        width: "80%",
        float: "right",
        overflow: "auto",
        marginTop: "6%",
        marginRight: "23px",
        marginBottom: "30px",
        paddingTop: "20px",
      }}
    >
      <Typography variant="h4" sx={{ paddingLeft: "20px" }}>
        Add Faqs
      </Typography>
      <AddFaqs />
      <Divider sx={{ margin: "20px 0", borderTop: "4px solid grey" }} />
      <DataGrid
        rows={table}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        getRowId={getRowId}
      />
    </Paper>
  );
}
