import axios from "axios";
import AppConstatnt from "../../constants/AppConstant";

const API = axios.create({ baseURL: `${AppConstatnt.baseURL}` });

export const getGalleryData = async (limit) =>
  await API.get(`/images/allimage?limit=${limit}`);

export const uploadImage = async (formData) =>
  await API.post(`/images/uploadimage`, formData);

export const deleteImage = async (formData) =>
  await API.post(`/images/deleteimage`, formData);

export const getImageType = async () => await API.get(`/images/imagetype`);

export const checkImageDimension = async () =>
  await API.get(`/images/checkDimension`);

export const updateImageData = async (formData) =>
  await API.post(`/images/updateImage`, formData);

export const getImageBySearch = async (formData) =>
  await API.post(`/images/searchImage`, formData);

export const sendTelegramMessageApi = (formData) =>
  API.post("/images/sendtelegrammsg", formData);
