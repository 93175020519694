import axios from "axios";
import AppConstatnt from "../../constants/AppConstant";

const API = axios.create({ baseURL: `${AppConstatnt.baseURL}` });

export const getRemovedExts = () =>
  API.get("/ext/recently-removed-extensions-graph");
export const getAddedExts = () =>
  API.get("/ext/recently-added-extensions-graph");
export const getUpdatedExts = () =>
  API.get("/ext/recently-updated-extensions-graph");

// login signup API
export const getDailySignup = (formData) =>
  API.post("/users/register-Users", formData);
export const getDailyLogin = (formData) =>
  API.post("/users/login-Users", formData);
