import React, { useEffect, useState } from "react";
import { Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { allExtensions } from "../../redux/Slices/extSlice";
import Datatable from "../mui-datatable/Datatable";
import { toast } from "react-toastify";
const AllExtensions = () => {
  const auth = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const allext = useSelector((state) => state.ext.table?.data);
  // console.log(allext, "allextallext");
  const [limit, setLimit] = useState(10);
  const [page, pagechange] = useState(0);
  const [rowsPerPage, rowsPerPageChange] = useState(50);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (auth) {
          let data = { limit };
          dispatch(allExtensions(data))
            .then((data) => {
              if (data.payload?.code == 200) {
                toast.success("Data loaded successfully!", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
              } else {
                toast.error("Something went wrong", {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 3000,
                });
              }
            })
            .catch((err) => {
              console.log(err, "err in all ext");
              toast.error("Something went wrong", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
              });
            });
        }
      } catch (error) {
        console.error("Error fetching extensions:", error);
      }
    };

    fetchData();
  }, [auth, dispatch, limit]);

  const columns = allext ? Object.keys(allext[0]) : [];
  const options = {
    selectableRows: "none",
    filter: true,
    print: false,
    download: false,
    pagination: true,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [50, 100, 150],
    page: page,
    onChangePage: (newPage) => {
      // console.log(page, "page in onchangepage");
      pagechange(newPage);
      if (newPage === Math.ceil(allext.length / rowsPerPage) - 1) {
        setLimit((prev) => prev + 15);
      }

      // pagechange({ ...page, current: page, prev: page - 1 });
    },
    onChangeRowsPerPage: (value) => {
      rowsPerPageChange(value);
      if (value > allext.length) {
        setLimit((prev) => prev + 15);
      }
      pagechange(0);
    },
  };

  return (
    <Paper
      sx={{
        width: "80%",
        float: "right",
        overflow: "auto",
        marginTop: "8%",
        marginRight: "23px",
      }}
    >
      <Datatable
        title="All Extension Details"
        data={allext}
        columns={columns}
        options={options}
      />
    </Paper>
  );
};

export default AllExtensions;
