import React, { useRef, useState } from 'react';
import { Button, TextField, CircularProgress,Grid } from '@mui/material';
import { Send } from '@mui/icons-material';
import { useDispatch} from 'react-redux'
import { getAllFaq,createFaq} from '../../redux/Slices/faqSlice'
import { toast } from 'react-toastify';
const AddFaqs = () => {

const dispatch = useDispatch();

  const questionRef = useRef(null);
  const answerRef = useRef(null);
  const pageNumberRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault(); 

    const formData = {
      question: questionRef.current.value,
      answer: answerRef.current.value,
      page: pageNumberRef.current.value,
    };
questionRef.current.value ="";
answerRef.current.value ="";
pageNumberRef.current.value ="";
    setIsLoading(true); 
    try {
        dispatch(createFaq(formData)).then(()=>{
            dispatch(getAllFaq())
            toast.success('FAQ added Successfully!', {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            })
        })

      
    } catch (error) {
      console.error('Error:', error);
      toast.error('Something went wrong', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ padding: '20px' }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            required
            inputRef={questionRef}
            name="question"
            label="Question"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            inputRef={pageNumberRef}
            name="page"
            label="Page Number"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            inputRef={answerRef}
            name="answer"
            label="Answer"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
          />
        </Grid>
       
        <Grid item xs={6} >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            startIcon={isLoading ? <CircularProgress size={20} color="inherit" /> : <Send />}
            disabled={isLoading}
          >
            {isLoading ? 'Adding...' : 'Add'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddFaqs;
