import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import ModeIcon from '@mui/icons-material/Mode';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import axios from 'axios';
import AppConstatnt from "../../constants/AppConstant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export default function BlogDatatable({ rows, setUpdate }) {

    const Navigate = useNavigate();
    let baseUrl = AppConstatnt.baseURL;

    const notify = (value) =>
        toast.success(value, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

    const failure = (value) =>
        toast.error(value, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });


    const handledit = (id) => {
        console.log(id, "idd");
        Navigate(`/blog/${id}`);
    }

    const handleblock = async (id) => {
        const blogdata = {
            active: 0,
            id: id,
        };
        axios.patch(`${baseUrl}` + '/seo/updateblog', { blogdata }).then((response) => {
            if (response.data.status == 200) {
                notify(response.data.message);
            }
            if (response.data.status == 500) {
                failure(response.data.message);
                setUpdate(prev => !prev);
            }
            setUpdate(prev => !prev);
        });

    }

    const handleunblock = async (id) => {
        const blogdata = {
            active: 1,
            id,
        };
        axios.patch(`${baseUrl}` + '/seo/updateblog', { blogdata }).then((response) => {
            if (response.data.status == 200) {
                notify(response.data.message);
            }
            if (response.data.status == 500) {
                failure(response.data.message);
                setUpdate(prev => !prev);
            }
            setUpdate(prev => !prev);
        });
    }

    const columns = [
        // { field: 'id', headerName: 'ID', width: 70 },
        { field: 'id', headerName: 'Sl', width: 70 },
        { field: 'ext_id', headerName: 'ExtId', width: 130 },
        { field: 'meta_title', headerName: 'Meta_Title', width: 130 },
        { field: 'meta_description', headerName: 'meta_description', width: 130 },
        { field: 'title', headerName: 'Title', width: 130 },
        { field: 'featured_img', headerName: 'Featured_img', width: 130 },
        { field: 'author', headerName: 'Author', width: 130 },
        { field: 'similar_blogs', headerName: 'Similar Blogs', width: 230 },
        { field: 'reading_time', headerName: 'Reading Time', width: 130 },
        { field: 'html', headerName: 'Html', width: 230 },
        { field: 'active', headerName: 'Active', width: 130 },
        {
            field: 'action',
            headerName: 'Action',
            width: 190,
            renderCell: (params) => {
                return (<div className="action flex gap-1" style={{ display: "flex", gap: "10px" }}>
                    <div className="view ">
                        <button className="bg-green-500" onClick={() => handledit(params.row.ext_id)} >
                            <ModeIcon sx={{ width: "15px", height: "15px", textAlign: 'center', paddingTop: '2px' }} />
                        </button>
                    </div>
                    {params.row.active == 1 && <div className="view">
                        <button className="bg-red-500 rounded-full  " onClick={() => handleblock(params.row.ext_id)} >
                            Deactivate
                        </button>
                    </div>}

                    {params.row.active == 0 && <div className="view " style={{ width: "89px" }}>
                        <button className="bg-red-500 rounded-full " style={{ width: "100%" }} onClick={() => handleunblock(params.row.ext_id)} >
                            Activate
                        </button>
                    </div>}

                </div>)
            },
        },
    ];

    return (
        <div style={{ paddingTop: "5%", display: 'flex', gap: "20%", textAlign: "center" }}>
            <div className='left' style={{ paddingTop: "20px", "flex": 1, marginLeft: '18%', overflowX: "scroll", marginRight: '15px' }}>
                <div style={{ height: 400, width: '100%' }}>
                    <DataGrid
                        rows={rows}
                        disableRowSelectionOnClick
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 5 },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                        checkboxSelection
                    />
                </div>
            </div>
        </div>
    );
}


