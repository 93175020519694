import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUnsubscribedUsers, getSubscribedUsers,getRegisterUserByDate } from "../api/usersapi";

export const unSubscribedUsers = createAsyncThunk('userStats/UnsubscribedUsers', async () => {
    try {
        const response = await getUnsubscribedUsers();
        console.log(response, "getUnsubscribedUsers")
        return response.data;
    } catch (error) {
        console.log(error, 'errrrr')
        throw error;
    }
});

export const subscribedUsers = createAsyncThunk('userStats/subscribedUsers', async () => {
    try {
        const response = await getSubscribedUsers();
        console.log(response, getSubscribedUsers);
        return response.data;
    } catch (error) {
        console.log(error, 'errrrr')
        throw error;
    }
});




const usersStatsSlice = createSlice({
    name: 'usersStatsSlice',
    initialState: {
        table: null,
        tableRegisterUserbyDate:null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(unSubscribedUsers.fulfilled, (state, action) => {
            state.table = action.payload;
        })
        builder.addCase(unSubscribedUsers.pending, (state, action) => {
            state.table = "pending";
        })
        builder.addCase(unSubscribedUsers.rejected, (state, action) => {
            state.table = null
        })
        builder.addCase(subscribedUsers.fulfilled, (state, action) => {
            state.table = action.payload;
        })
        builder.addCase(subscribedUsers.rejected, (state, action) => {
            state.table = null;
        })
        builder.addCase(subscribedUsers.pending, (state, action) => {
            state.table = "pending";
        })
    
    }

})

export default usersStatsSlice.reducer;
