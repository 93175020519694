/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Paper,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useDispatch, useSelector } from "react-redux";
import {
  getGalleryData,
  deleteImageData,
  updateImageData,
} from "../../redux/Slices/gallerySlice";
import { Image, message, Popconfirm, Skeleton } from "antd";
import ButtonShowMore from "./ButtonLoadMore";

function ShowImages() {
  const dispatch = useDispatch();
  const galleryImages = useSelector(
    (state) =>
      state.gallery.data && state.gallery.data.data && state.gallery.data.data
  );

  const [limit, setLimit] = useState(12);

  // Dialog
  const [open, setOpen] = React.useState(false);

  const [dialogData, setDialogData] = useState({
    id: "",
    title: "",
    alt_text: "",
    img_url: "",
    priority: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    setOpen(false);
    console.log(dialogData, "dialog");
    dispatch(updateImageData({ dialogData, cbFunc }));
  };

  const handleImageInputsChange = (key) => (e) => {
    console.log(e.target.value, "value");
    setDialogData({ ...dialogData, [key]: e.target.value });
  };

  const copyImageUrl = async (e, img_url) => {
    e.preventDefault();
    await navigator.clipboard.writeText(img_url && img_url);
    message.success("Image url Copied");
  };

  // Edit image properties
  const handleEditImage = (e, data) => {
    e.preventDefault();
    setDialogData(data);
    setOpen(true);
  };

  // Delete image
  const handleDeleteImage = (e, item) => {
    e.preventDefault();
    let deletedData = {};
    deletedData.id = item.id;
    deletedData.img_url = item.img_url;
    dispatch(deleteImageData({ deletedData, cbFunc }));
  };

  const cbFunc = () => {
    dispatch(getGalleryData(limit));
  };

  // call on limit change
  useEffect(() => {
    dispatch(getGalleryData(limit));
  }, [limit]);

  useEffect(() => {
    cbFunc();
  }, []);

  return (
    <div style={{ paddingLeft: "20%" }}>
      <div
        style={{
          paddingTop: "4%",
          display: "flex",
          gap: "20%",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Paper
          style={{
            width: "100%",
          }}
        >
          <Toolbar
            mt={3}
            sx={{
              padding: "1.2rem",
              borderBottom: "2px solid var(--primary-color)",
            }}
          >
            <Typography
              sx={{
                flex: "1 1 100%",
                color: "var(--primary-color)",
                fontWeight: "bold",
              }}
              variant="h5"
              id="tableTitle"
            >
              Show Image
            </Typography>
          </Toolbar>

          <Box
            px={2}
            pt={4}
            pb={2}
            sx={{ width: "100%", height: "75vh", overflowY: "scroll" }}
          >
            <Image.PreviewGroup>
              <ImageList cols={3} gap={16} style={{ height: "100%" }}>
                {galleryImages ? (
                  galleryImages.map((item) => (
                    <ImageListItem key={item.id}>
                      <Image
                        src={item.img_url}
                        alt={item.alt_text ? item.alt_text : ""}
                        pr
                        eview
                        style={{ height: 156, objectFit: "contain" }}
                      />
                      <ImageListItemBar
                        sx={{ background: "transparent" }}
                        position="top"
                        actionIcon={
                          <Tooltip title="Edit Image Data" arrow>
                            <IconButton
                              sx={{
                                borderRadius: "0 0 0 50%",
                                width: "32px",
                                height: "32px",
                                color: "white",
                                bgcolor: "var(--secondary-color)",
                                "&:hover": {
                                  bgcolor: "var(--secondary-color)",
                                  filter: "contrast(2)",
                                },
                              }}
                              onClick={(e) => handleEditImage(e, item)}
                            >
                              <EditIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                          </Tooltip>
                        }
                        actionPosition="right"
                      />
                      <ImageListItemBar
                        sx={{
                          // padding: '7px 10px !important',
                          "& .MuiImageListItemBar-titleWrap": {
                            padding: "7px 10px !important",
                          },
                        }}
                        subtitle={
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Box>
                              <Typography>
                                <b>
                                  {item.priority ? item.priority : " - "},{" "}
                                  {item.title}
                                </b>
                              </Typography>
                            </Box>
                            <Stack direction="row" spacing={2}>
                              <Tooltip title="Copy ImageUrl">
                                <IconButton
                                  size="small"
                                  color="warning"
                                  sx={{
                                    width: "32px",
                                    height: "32px",
                                    color: "black",
                                    bgcolor: "var(--bs-light)",
                                    "&:hover": {
                                      color: "black",
                                      bgcolor: "var(--bs-light)",
                                      filter: "contrast(2)",
                                    },
                                  }}
                                  onClick={(e) => copyImageUrl(e, item.img_url)}
                                >
                                  <ContentCopyIcon sx={{ fontSize: 18 }} />
                                </IconButton>
                              </Tooltip>
                              <Popconfirm
                                placement="bottom"
                                title="Delete Image"
                                description="Do you really want to delete this image?"
                                okText="Yes"
                                cancelText="No"
                                onConfirm={(e) => handleDeleteImage(e, item)}
                              >
                                <Tooltip title="Delete Banner">
                                  <IconButton
                                    size="small"
                                    color="error"
                                    sx={{
                                      width: "32px",
                                      height: "32px",
                                      color: "white",
                                      bgcolor: "var(--bs-red)",
                                      "&:hover": {
                                        color: "white",
                                        bgcolor: "var(--bs-red)",
                                        filter: "contrast(2)",
                                      },
                                    }}
                                  >
                                    <DeleteIcon sx={{ fontSize: 18 }} />
                                  </IconButton>
                                </Tooltip>
                              </Popconfirm>
                            </Stack>
                          </Stack>
                        }
                      />
                    </ImageListItem>
                  ))
                ) : (
                  <Skeleton.Image active />
                )}
              </ImageList>
            </Image.PreviewGroup>
            <Box className="text-center pt-2">
              <button
                style={{ border: "none", background: "inherit" }}
                onClick={() => setLimit((prev) => prev + 12)}
              >
                <ButtonShowMore />
              </button>
            </Box>
          </Box>

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
              <b>Edit Data</b>
            </DialogTitle>
            <DialogContent>
              <Box sx={{ width: "480px" }}>
                <img
                  src={dialogData.img_url}
                  alt={dialogData.title}
                  style={{
                    width: "100%",
                    aspectRatio: "initial",
                  }}
                />
              </Box>

              <Box mt={2} className="d-flex align-items-center gap-2">
                <Typography>
                  <b>New Priority Number : </b>
                </Typography>
                <TextField
                  autoFocus
                  size="small"
                  value={dialogData.priority}
                  type="number"
                  onChange={handleImageInputsChange("priority")}
                />
              </Box>

              <Box mt={2} className="d-flex align-items-center gap-2">
                <Typography>
                  <b>New Title : </b>
                </Typography>
                <TextField
                  autoFocus
                  size="small"
                  value={dialogData.title}
                  type="string"
                  onChange={handleImageInputsChange("title")}
                />
              </Box>
              <Box mt={2} className="d-flex align-items-center gap-2">
                <Typography>
                  <b>New alt_text : </b>
                </Typography>
                <TextField
                  autoFocus
                  size="small"
                  value={dialogData.alt_text}
                  type="string"
                  onChange={handleImageInputsChange("alt_text")}
                />
              </Box>
            </DialogContent>
            <DialogActions p={3}>
              <Button variant="outlined" color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </div>
    </div>
  );
}

export default ShowImages;
