import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getFaqAPI,createFaqAPI,updateFaqAPI } from "../api/faqapi";


export const getAllFaq = createAsyncThunk('faq/getall',async()=>{
    try {
        const response = await getFaqAPI();
        return response.data
    } catch (error) {
        console.log(error, 'errrrr')
        throw error;
    }
})
export const createFaq = createAsyncThunk('faq/create',async(formdata)=>{
    try {
        const response = await createFaqAPI(formdata);
        return response.data
    } catch (error) {
        console.log(error, 'errrrr')
        throw error;
    }
})
export const updateFaq = createAsyncThunk('faq/update',async(formdata)=>{
    try {
        const response = await updateFaqAPI(formdata);
        return response.data
    } catch (error) {
        console.log(error, 'errrrr')
        throw error;
    }
})


 const FaqSlice = createSlice({
    name:"FaqSlice",
    initialState:{
        faqtable:null,
        message:""
    },
    extraReducers:(builder)=>{
        builder.addCase(getAllFaq.fulfilled,(state,action)=>{
            console.log(action.payload)
            state.faqtable = action.payload.data;
            
        })
        builder.addCase(getAllFaq.rejected,(state,action)=>{
            state.faqtable = null;
        })
        builder.addCase(createFaq.fulfilled,(state,action)=>{
            state.message = action.payload.message
        })
        builder.addCase(createFaq.rejected,(state,action)=>{
            state.message = "";
        })
        builder.addCase(updateFaq.fulfilled,(state,action)=>{
            console.log(action.payload)
            state.message = action.payload.message;
        })
        builder.addCase(updateFaq.rejected,(state,action)=>{
            state.message = "";
        })
     
    }
})

export default FaqSlice.reducer;