import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  getAllExt,
  getRecentlyAddedExt,
  getRecentlyRemovedExt,
  getRecentlyUpdatedExt,
  getExtensionByCategory,
  mostDownloadedExt,
  getMenifestExt,
} from "../api/extapi";

export const allExtensions = createAsyncThunk("extension/all", async (data) => {
  try {
    const response = await getAllExt(data);
    return response.data;
  } catch (error) {
    console.log(error.message, "errorrrrr");
    throw error; // You should rethrow the error to handle it in the component.
  }
});
export const recenltyUpdatedExt = createAsyncThunk(
  "recentlyUpdatedext/all",
  async (formdata) => {
    try {
      const response = await getRecentlyUpdatedExt(formdata);
      return response.data;
    } catch (error) {
      console.log(error.message, "errorrrrr");
      throw error; // You should rethrow the error to handle it in the component.
    }
  }
);
export const recentlyRemovedExt = createAsyncThunk(
  "recentlyRemovedext/all",
  async (formdata) => {
    try {
      const response = await getRecentlyRemovedExt(formdata);
      return response.data;
    } catch (error) {
      console.log(error.message, "errorrrrr");
      throw error; // You should rethrow the error to handle it in the component.
    }
  }
);
export const recentlyAddedExt = createAsyncThunk(
  "recentlyAddedext/all",
  async (formdata) => {
    try {
      const response = await getRecentlyAddedExt(formdata);
      return response.data;
    } catch (error) {
      console.log(error.message, "errorrrrr");
      throw error; // You should rethrow the error to handle it in the component.
    }
  }
);
export const getExtByCat = createAsyncThunk(
  "extensionbycategory/type",
  async (formdata) => {
    try {
      const response = await getExtensionByCategory(formdata);
      return response.data;
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }
);
export const getMostdownloadedExt = createAsyncThunk(
  "extension/mostdownloaded",
  async (formdata) => {
    try {
      const response = await mostDownloadedExt(formdata);
      // console.log(response.data, "response.data-----------------");
      return response.data;
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }
);
export const getMenifestExtension = createAsyncThunk(
  "extension/menifest",
  async (formdata) => {
    try {
      const response = await getMenifestExt(formdata);
      return response.data;
    } catch (error) {
      console.log(error.message);
      throw error;
    }
  }
);
const extSlice = createSlice({
  name: "extSlice",
  initialState: {
    table: null,
    tableAddedExt: null,
    tableUpdatedExt: null,
    tableRemovedExt: null,
    tableExtbyCate: null,
    tableMostDownloadedExt: null,
    tablemanifestExt: null,
    total_most_downloaded_ext: null,
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(allExtensions.fulfilled, (state, action) => {
      state.table = action.payload;
    });
    builder.addCase(allExtensions.rejected, (state, action) => {
      state.table = null;
    });
    builder.addCase(recenltyUpdatedExt.fulfilled, (state, action) => {
      state.tableUpdatedExt = action.payload;
    });
    builder.addCase(recenltyUpdatedExt.rejected, (state, action) => {
      state.tableUpdatedExt = null;
    });
    builder.addCase(recentlyAddedExt.fulfilled, (state, action) => {
      state.tableAddedExt = action.payload;
    });
    builder.addCase(recentlyAddedExt.rejected, (state, action) => {
      state.tableAddedExt = null;
    });
    builder.addCase(recentlyRemovedExt.fulfilled, (state, action) => {
      state.tableRemovedExt = action.payload;
    });
    builder.addCase(recentlyRemovedExt.rejected, (state, action) => {
      state.tableRemovedExt = null;
    });
    builder.addCase(getExtByCat.fulfilled, (state, action) => {
      state.tableExtbyCate = action.payload;
    });
    builder.addCase(getExtByCat.rejected, (state, action) => {
      state.tableExtbyCate = null;
    });

    builder.addCase(getMostdownloadedExt.pending, (state, action) => {
      state.tableMostDownloadedExt = null;
      state.loading = true;
      state.total_most_downloaded_ext = null;
    });
    builder.addCase(getMostdownloadedExt.fulfilled, (state, action) => {
      state.tableMostDownloadedExt = action.payload;
      state.total_most_downloaded_ext =
        action.payload.total_most_downloaded_ext;
      state.loading = false;
    });
    builder.addCase(getMostdownloadedExt.rejected, (state, action) => {
      state.tableMostDownloadedExt = null;
      state.loading = false;
      state.total_most_downloaded_ext = null;
    });
    builder.addCase(getMenifestExtension.fulfilled, (state, action) => {
      state.tablemanifestExt = action.payload;
    });
    builder.addCase(getMenifestExtension.rejected, (state, action) => {
      state.tablemanifestExt = null;
    });
  },
});

export default extSlice.reducer;
