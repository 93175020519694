import axios from "axios";
import AppConstatnt from "../../constants/AppConstant";

const API = axios.create({ baseURL: `${AppConstatnt.baseURL}` });

export const getAllTestimonial = () =>
  API.get("/testimonial/getAllTestimonial");
export const createTestimonial = (formdata) =>
  API.post("/testimonial/createTestimonial", formdata);
export const updateTestimonial = (formdata) =>
  API.put("/testimonial/updateTestimonial", formdata);
export const deleteTestimonial = (formdata) =>
  API.delete("/testimonial/deleteTestimonial", { data: formdata });
