import React, { useState, useRef, useMemo, useEffect } from "react";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button, Typography } from "@mui/material";
import axios from "axios";
import AppConstatnt from "../../constants/AppConstant";
import { ToastContainer, toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import "react-toastify/dist/ReactToastify.css";
import JoditEditor from "jodit-react";
import Textarea from "@mui/joy/Textarea";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
// import { stateToHTML } from 'draft-js-export-html';
// import { ContentState, convertToRaw } from 'draft-js';

export default function Allblogdetails({ setUpdate }) {
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  const [extid, setExtid] = useState("");
  const [typedValue, setTypedValue] = useState("");
  const [simtypedValue, setSimTypedValue] = useState("");
  const [metatitle, setMetatitle] = useState("");
  const [metadesc, setMetadesc] = useState("");
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [featuredimg, setFeaturedimg] = useState("");
  const [similarBlog, setSimilarBlog] = useState([]);
  const [readingTime, setReadingTime] = useState("");
  const [top100Films, setTop100Films] = useState([]);
  const [similarones, setSimilarones] = useState([]);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [descriptionType, setDescriptionType] = useState(true);
  const [checked, setChecked] = useState(true);
  const [htmlType, setHtmlType] = useState(" ");
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const divRef = useRef(null);
  const [divWidth, setDivWidth] = useState(null);
  const [divHeight, setDivHeight] = useState(null);

  let html;

  let baseUrl = AppConstatnt.baseURL;
  console.log(baseUrl, "baseUrl");

  useEffect(() => {
    if (content) {
      handletext();
    }
  }, [content]);

  console.log(personName, "personName");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const extresponse = await axios.get(
          "https://complete.crxinsider.com/complete?q=" + `${typedValue}`
        );
        const typedname = extresponse[0];
        // console.log(typedname, "typedname");
        // console.log(extresponse.data, "extresponse");
        const mappedFilms = extresponse.data.map((item) => ({
          label: item.name,
          extid: item.ext_id,
        }));
        setTop100Films(mappedFilms);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const debounceInputVal = setTimeout(() => {
      fetchData();
    }, 300);

    return () => clearTimeout(debounceInputVal);
  }, [typedValue]);

  useEffect(() => {
    if (simtypedValue) {
      const fetchDatasumilar = async () => {
        try {
          // const labelname = extid.label;
          // console.log(labelname, "labelname");
          const extresponsesimilar = await axios.get(
            "https://complete.crxinsider.com/complete?q=" + `${simtypedValue}`
          );
          //   console.log(extresponsesimilar.data, "extresponse");
          const mappedFilms = extresponsesimilar.data.map((item) => ({
            label: item.name,
            extid: item.ext_id,
          }));
          setSimilarones(mappedFilms);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchDatasumilar();
    }
  }, [simtypedValue]);

  useEffect(() => {
    const handleResize = () => {
      if (divRef.current) {
        const { offsetWidth, offsetHeight } = divRef.current;
        setDivWidth(offsetWidth);
        setDivHeight(offsetHeight);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const notify = (value) =>
    toast.success(value, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const failure = (value) =>
    toast.error(value, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const handleExtidChange = (event, newValue) => {
    console.log(newValue, "newValue");
    setExtid(newValue?.extid);
  };
  const handleSimExtidChange = (event, newValue) => {
    console.log(newValue, "newValuesim");
    if (newValue) {
      setSimilarBlog((prevSimilarBlogs) => [...prevSimilarBlogs, newValue]);
    }
  };
  //   console.log(extid, "extid");
  //   console.log(similarBlog, "similarBlog");

  const handleTypedValueChange = (event) => {
    const newValue = event.target.value;
    setTypedValue(newValue);
  };
  const handleSimilarTypedValueChange = (event) => {
    const newValue = event.target.value;
    setSimTypedValue(newValue);
  };

  //   console.log(typedValue, "typedValue");
  //   console.log(simtypedValue, "simtypedValue");

  const handleChangestate = (event) => {
    setChecked(event.target.checked);
    setDescriptionType((prev) => !prev);
  };

  //   console.log(personName, "personName");

  const handletext = () => {
    setHtmlType(content);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    // Append all blog data to formData
    formData.append("ext_id", extid);
    formData.append("meta_title", metatitle);
    formData.append("meta_description", metadesc);
    formData.append("title", title);
    formData.append("author", author);
    formData.append("similar_blogs", JSON.stringify(similarBlog));
    formData.append("reading_time", readingTime);
    formData.append("html", htmlType);

    // Append either the uploaded image or the image URL
    if (uploadedImage) {
      formData.append("featured_img", uploadedImage);
    } else {
      formData.append("featured_img_url", featuredimg);
    }
    for (let [key, value] of formData.entries()) {
      console.log(key, value, "forms");
    }
    try {
      console.log("runned");
      const response = await axios.post(
        `${AppConstatnt.baseURL}/seo/addblogs`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(response, "response");
      if (response.data.code === 200) {
        notify(response.data.message);
      } else if (response.data.code === 500) {
        failure(response.data.message);
      }

      setUpdate((prev) => !prev);

      // Reset all form fields
      setExtid("");
      setPersonName([]);
      setTypedValue("");
      setMetatitle("");
      setMetadesc("");
      setTitle("");
      setAuthor("");
      setFeaturedimg("");
      setUploadedImage(null);
      setSimilarBlog([]);
      setReadingTime("");
      setTop100Films([]);
      setContent("");
      setHtmlType("");
    } catch (error) {
      console.error("Error submitting blog:", error);
      failure("Failed to submit blog");
    }
  };

  const handleDelete = (id) => {
    console.info(id, "You clicked the delete icon.");
    const rslt = similarBlog.filter((item) => item.extid != id);
    setSimilarBlog(rslt);
  };

  //   console.log(personName, "personName");
  //   console.log(similarones, "similarones");
  //   console.log(divWidth, "divWidth");
  //   console.log(divHeight, "divHeight");

  return (
    <div>
      <div
        style={{
          paddingTop: "4%",
          display: "flex",
          gap: "20%",
          textAlign: "center",
        }}
      >
        <h1 style={{ paddingLeft: "18%" }}>Add Blog</h1>
      </div>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: "flex",
            gap: "20%",
            textAlign: "center",
            paddingLeft: "18%",
            justifyContent: "space-around",
            marginBottom: "30px",
          }}
        >
          <div style={{ width: "100%" }}>
            {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={top100Films}
              sx={{ width: "100 %" }}
              value={extid} // Controlled component value
              onChange={handleExtidChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ext Id"
                  onChange={handleTypedValueChange}
                />
              )}
            />
          </div>
          <div style={{ width: "100%", marginRight: "15px" }}>
            <TextField
              helperText="Please enter your featured image url"
              id="demo-helper-text-aligned"
              label="featured Image"
              value={featuredimg}
              fullWidth
              onChange={(e) => {
                setFeaturedimg(e.target.value);
              }}
            />
            <Typography
              variant="body2"
              style={{ marginTop: "10px", marginBottom: "5px" }}
            >
              OR
            </Typography>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="raised-button-file"
              type="file"
              name="image"
              onChange={(e) => setUploadedImage(e.target.files[0])}
            />
            <label htmlFor="raised-button-file">
              <Button variant="contained" component="span">
                Upload Image
              </Button>
            </label>
            {uploadedImage && (
              <Typography variant="body2">{uploadedImage.name}</Typography>
            )}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "20%",
            textAlign: "center",
            paddingLeft: "18%",
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "100%" }}>
            {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
            <TextField
              helperText="Please enter your meta title"
              id="demo-helper-text-aligned"
              label="meta_title"
              value={metatitle}
              fullWidth
              onChange={(e) => {
                setMetatitle(e.target.value);
              }}
            />
          </div>
          <div style={{ width: "100%", marginRight: "15px" }}>
            <TextField
              helperText="Please enter your meta description"
              id="demo-helper-text-aligned"
              value={metadesc}
              label="meta_description"
              fullWidth
              onChange={(e) => {
                setMetadesc(e.target.value);
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "20%",
            textAlign: "center",
            paddingLeft: "18%",
            justifyContent: "space-around",
            paddingTop: "30px",
          }}
        >
          <div ref={divRef} style={{ width: "100%" }}>
            {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
            <TextField
              helperText="Please enter your title for blog"
              id="demo-helper-text-aligned"
              value={title}
              label="title"
              fullWidth
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
          <div style={{ width: "100%", marginRight: "15px" }}>
            <TextField
              helperText="Please enter author name"
              id="demo-helper-text-aligned"
              value={author}
              label="author"
              fullWidth
              onChange={(e) => {
                setAuthor(e.target.value);
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "20%",
            textAlign: "center",
            paddingLeft: "18%",
            justifyContent: "space-around",
            paddingTop: "30px",
            maxWidth: `${divWidth}`,
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={similarones}
                sx={{ width: "100 %" }}
                value={""} // Controlled component value
                onChange={handleSimExtidChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Simialar Id"
                    onChange={handleSimilarTypedValueChange}
                  />
                )}
              />
            </div>
          </div>
          <div style={{ width: "100%", marginRight: "15px" }}>
            <TextField
              helperText="Please enter Reading Time"
              id="demo-helper-text-aligned"
              value={readingTime}
              label="Reading Time"
              fullWidth
              onChange={(e) => {
                setReadingTime(e.target.value);
              }}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "20%",
            textAlign: "center",
            paddingLeft: "18%",
            justifyContent: "space-around",
            paddingTop: "5px",
            marginBottom: "10px",
          }}
        >
          <div style={{ width: "100%", maxWidth: `${divWidth}` }}>
            <Stack direction="row" spacing={1}>
              {similarBlog.length > 0 &&
                similarBlog.map((item) => (
                  <Chip
                    label={item.label}
                    onDelete={() => {
                      handleDelete(item.extid);
                    }}
                  />
                ))}
            </Stack>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            gap: "5%",
            textAlign: "center",
            paddingLeft: "18%",

            paddingTop: "10px",
            marginBottom: "5px",
          }}
        >
          <Switch
            checked={checked}
            onChange={handleChangestate}
            inputProps={{ "aria-label": "controlled" }}
          />
        </div>

        <div
          style={{
            display: "flex",
            gap: "5%",
            paddingLeft: "18%",
            justifyContent: "space-around",
            paddingTop: "3px",
          }}
        >
          {descriptionType && (
            <div style={{ width: "100%", marginRight: "15px" }}>
              {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
              <p style={{ paddingLeft: "5px" }}>Text Editor</p>
              <JoditEditor
                ref={editor}
                value={content}
                // onChange={() =>handletext}
                onChange={(newContent) => setContent(newContent)}
              />
            </div>
          )}

          {!descriptionType && (
            <div style={{ width: "100%", marginRight: "15px" }}>
              {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
              <p style={{ paddingLeft: "5px" }}>Hmtl</p>

              <Textarea
                style={{
                  width: "100%",
                  minHeight: "200px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                value={htmlType}
                placeholder="Type anything…"
              />
              {/* <textarea style={{ width: '100%'}} value={htmlType} placeholder="Type anything…" /> */}
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            gap: "20%",
            textAlign: "center",
            paddingLeft: "18%",
            justifyContent: "space-around",
            paddingTop: "30px",
            marginBottom: "5px",
          }}
        >
          <div style={{ width: "100%" }}>
            <button style={{ padding: "8px" }}>Submit</button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

// import React, { useState, useRef, useMemo, useEffect } from 'react';
// import Switch from '@mui/material/Switch';
// import TextField from '@mui/material/TextField';
// import { useTheme } from '@mui/material/styles';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import axios from 'axios';
// import AppConstatnt from "../../constants/AppConstant";
// import { ToastContainer, toast } from "react-toastify";
// import Autocomplete from '@mui/material/Autocomplete';
// import "react-toastify/dist/ReactToastify.css";
// import JoditEditor from 'jodit-react';
// import Textarea from '@mui/joy/Textarea';
// import Chip from '@mui/material/Chip';
// import Stack from '@mui/material/Stack';
// // import { stateToHTML } from 'draft-js-export-html';
// // import { ContentState, convertToRaw } from 'draft-js';

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//     PaperProps: {
//         style: {
//             maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//             width: 250,
//         },
//     },
// };

// function getStyles(name, personName, theme) {
//     return {
//         fontWeight:
//             personName.indexOf(name) === -1
//                 ? theme.typography.fontWeightRegular
//                 : theme.typography.fontWeightMedium,
//     };
// }

// export default function Allblogdetails({ setUpdate }) {

//     const theme = useTheme();
//     const [personName, setPersonName] = React.useState([]);
//     const [extid, setExtid] = useState('');
//     const [typedValue, setTypedValue] = useState('');
//     const [metatitle, setMetatitle] = useState('');
//     const [metadesc, setMetadesc] = useState('');
//     const [title, setTitle] = useState('');
//     const [author, setAuthor] = useState('');
//     const [featuredimg, setFeaturedimg] = useState('');
//     const [similarBlog, setSimilarBlog] = useState([]);
//     const [readingTime, setReadingTime] = useState('');
//     const [top100Films, setTop100Films] = useState([]);
//     const [similarones, setSimilarones] = useState([]);

//     const [descriptionType, setDescriptionType] = useState(true);
//     const [checked, setChecked] = useState(true);
//     const [htmlType, setHtmlType] = useState(" ");
//     const editor = useRef(null);
//     const [content, setContent] = useState('');
//     const divRef = useRef(null);
//     const [divWidth, setDivWidth] = useState(null);
//     const [divHeight, setDivHeight] = useState(null);

//     let html;

//     let baseUrl = AppConstatnt.baseURL;
//     console.log(baseUrl, 'baseUrl');

//     useEffect(() => {
//         if (content) {
//             handletext();
//         }
//     }, [content])

//     console.log(personName, "personName");

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const extresponse = await axios.get('https://complete.crxinsider.com/complete?q=' + `${typedValue}`);
//                 const typedname = extresponse[0];
//                 console.log(typedname, "typedname");
//                 console.log(extresponse.data, "extresponse");
//                 const mappedFilms = extresponse.data.map((item) => ({
//                     label: item.name,
//                     extid: item.ext_id,
//                 }));
//                 setTop100Films(mappedFilms);
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         const debounceInputVal = setTimeout(() => {
//             fetchData();
//         }, 300);

//         return () => clearTimeout(debounceInputVal);
//     }, [typedValue]);

//     useEffect(() => {
//         if (extid) {
//             const fetchDatasumilar = async () => {
//                 try {
//                     const labelname = extid.label;
//                     console.log(labelname, "labelname");
//                     const extresponsesimilar = await axios.get('https://complete.crxinsider.com/complete?q=' + `${labelname}`);
//                     console.log(extresponsesimilar.data, "extresponse");
//                     const mappedFilms = extresponsesimilar.data.map((item) => ({
//                         label: item.name,
//                         extid: item.ext_id,
//                     }));
//                     setSimilarones(mappedFilms);
//                 } catch (error) {
//                     console.error('Error fetching data:', error);
//                 }
//             };
//             fetchDatasumilar();
//         }
//     }, [extid]);

//     useEffect(() => {
//         const handleResize = () => {
//             if (divRef.current) {
//                 const { offsetWidth, offsetHeight } = divRef.current;
//                 setDivWidth(offsetWidth);
//                 setDivHeight(offsetHeight);
//             }
//         };

//         handleResize();
//         window.addEventListener('resize', handleResize);
//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

//     const notify = (value) =>
//         toast.success(value, {
//             position: "top-right",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//         });
//     const failure = (value) =>
//         toast.error(value, {
//             position: "top-right",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//         });

//     const handleExtidChange = (event, newValue) => {
//         console.log(newValue, "newValue");
//         setExtid(newValue);
//     };
//     console.log(extid, "extid");
//     const handleTypedValueChange = (event) => {
//         const newValue = event.target.value;
//         setTypedValue(newValue);
//     };

//     console.log(typedValue, "typedValue")

//     const handleChangestate = (event) => {
//         setChecked(event.target.checked);
//         setDescriptionType(prev => !prev);
//     };

//     console.log(personName, "personName");

//     const handletext = () => {
//         setHtmlType(content);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const blogdata = {
//             ext_id: extid,
//             meta_title: metatitle,
//             meta_description: metadesc,
//             title: title,
//             featured_img: featuredimg,
//             author: author,
//             similar_blogs: personName,
//             reading_time: readingTime,
//             html: htmlType
//         };

//         console.log(extid, metatitle, metadesc, title, author, featuredimg, similarBlog, readingTime, htmlType, " extid,metatitle, metadesc, title, author, featuredimg, similarBlog, readingTime, htmlType ")
//         axios.post(`${baseUrl}` + '/seo/addblogs', { blogdata }).then((response) => {
//             console.log(response.data.message, "responseblog");
//             if (response.data.status == 200) {
//                 notify(response.data.message);
//             }
//             if (response.data.status == 500) {
//                 failure(response.data.message);
//             }
//             setUpdate(prev => !prev);
//             setExtid('');
//             setPersonName([]);
//             setTypedValue('');
//             setMetatitle('');
//             setMetadesc('');
//             setTitle('');
//             setAuthor('');
//             setFeaturedimg('');
//             setSimilarBlog([]);
//             setReadingTime('');
//             setTop100Films([]);
//             setContent('');
//             setHtmlType('');
//         }).catch((error) => {
//             console.log(error, "errorblog");
//         })
//     }

//     function getStyles(name, personName, theme) {
//         return {
//             fontWeight:
//                 personName.indexOf(name) === -1
//                     ? theme.typography.fontWeightRegular
//                     : theme.typography.fontWeightMedium,
//         };
//     }

//     const handleChange = (event) => {
//         const {
//             target: { value },
//         } = event;
//         setPersonName(
//             // On autofill we get a stringified value.
//             typeof value === 'string' ? value.split(',') : value,
//         );
//     };

//     console.log(personName, "personName");
//     console.log(similarones, "similarones");
//     console.log(divWidth, "divWidth");
//     console.log(divHeight, "divHeight");

//     return (
//         <div style={{ marginTop: "30px" }}>
//             <div
//                 style={{
//                     paddingTop: "4%",
//                     display: "flex",
//                     gap: "20%",
//                     textAlign: "center",
//                 }}
//             >
//                 <h1 style={{ paddingLeft: "18%", }}>Add Blog</h1>
//             </div>
//             <form onSubmit={handleSubmit}>
//                 <div style={{
//                     display: "flex",
//                     gap: "20%",
//                     textAlign: "center",
//                     paddingLeft: "18%",
//                     justifyContent: 'space-around',
//                     marginBottom: '30px'
//                 }}>
//                     <div style={{ width: '100%' }}>
//                         {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
//                         <Autocomplete
//                             disablePortal
//                             id="combo-box-demo"
//                             options={top100Films}
//                             sx={{ width: '100 %' }}
//                             value={extid} // Controlled component value
//                             onChange={handleExtidChange}
//                             renderInput={(params) => <TextField {...params} label="Ext Id" onChange={handleTypedValueChange} />}
//                         />
//                     </div>
//                     <div style={{ width: '100%' }}>
//                         <TextField
//                             helperText="Please enter your featured img"
//                             id="demo-helper-text-aligned"
//                             label="featured Img"
//                             value={featuredimg}
//                             fullWidth
//                             onChange={(e) => { setFeaturedimg(e.target.value) }}
//                         />
//                     </div>
//                 </div>

//                 <div style={{
//                     display: "flex",
//                     gap: "20%",
//                     textAlign: "center",
//                     paddingLeft: "18%",
//                     justifyContent: 'space-around'

//                 }}>
//                     <div style={{ width: '100%' }}>
//                         {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
//                         <TextField
//                             helperText="Please enter your meta title"
//                             id="demo-helper-text-aligned"
//                             label="meta_title"
//                             value={metatitle}
//                             fullWidth
//                             onChange={(e) => {
//                                 setMetatitle(e.target.value);
//                             }}
//                         />
//                     </div>
//                     <div style={{ width: '100%' }}>
//                         <TextField
//                             helperText="Please enter your meta description"
//                             id="demo-helper-text-aligned"
//                             value={metadesc}
//                             label="meta_description"
//                             fullWidth
//                             onChange={(e) => {
//                                 setMetadesc(e.target.value);
//                             }}
//                         />
//                     </div>
//                 </div>

//                 <div style={{
//                     display: "flex",
//                     gap: "20%",
//                     textAlign: "center",
//                     paddingLeft: "18%",
//                     justifyContent: 'space-around',
//                     paddingTop: '30px'

//                 }}>
//                     <div ref={divRef} style={{ width: '100%' }}>
//                         {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
//                         <TextField
//                             helperText="Please enter your title for blog"
//                             id="demo-helper-text-aligned"
//                             value={title}
//                             label="title"
//                             fullWidth
//                             onChange={(e) => {
//                                 setTitle(e.target.value);
//                             }}
//                         />
//                     </div>
//                     <div style={{ width: '100%' }}>
//                         <TextField
//                             helperText="Please enter author name"
//                             id="demo-helper-text-aligned"
//                             value={author}
//                             label="author"
//                             fullWidth
//                             onChange={(e) => {
//                                 setAuthor(e.target.value);
//                             }}
//                         />
//                     </div>
//                 </div>

//                 <div style={{
//                     display: "flex",
//                     gap: "20%",
//                     textAlign: "center",
//                     paddingLeft: "18%",
//                     justifyContent: 'space-around',
//                     paddingTop: '30px'
//                 }}>
//                     <div style={{ width: '100%', maxWidth: `${divWidth}` }}>
//                         {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
//                         <FormControl sx={{ m: 0, width: '100%' }}>
//                             <InputLabel id="demo-multiple-name-label" >Similar</InputLabel>
//                             <Select

//                                 labelId="demo-multiple-name-label"
//                                 id="demo-multiple-name"
//                                 multiple
//                                 value={personName}
//                                 onChange={handleChange}
//                                 input={<OutlinedInput label="Name" />}
//                                 MenuProps={MenuProps}

//                             >
//                                 {similarones.map((name) => (
//                                     <MenuItem
//                                         key={name}
//                                         value={name}
//                                         style={getStyles(name, personName, theme)}
//                                     >
//                                         {name.label}
//                                     </MenuItem>
//                                 ))}
//                             </Select>
//                         </FormControl>
//                         {/* <TextField
//                             helperText="Please enter Similar Blog"
//                             id="demo-helper-text-aligned"
//                             value={similarBlog}
//                             label="Similar Blog"
//                             fullWidth
//                             onChange={(e) => {
//                                 setSimilarBlog(e.target.value);
//                             }}
//                         /> */}
//                     </div>
//                     <div style={{ width: '100%' }}>
//                         <TextField
//                             helperText="Please enter Reading Time"
//                             id="demo-helper-text-aligned"
//                             value={readingTime}
//                             label="Reading Time"
//                             fullWidth
//                             onChange={(e) => {
//                                 setReadingTime(e.target.value);
//                             }}
//                         />
//                     </div>
//                 </div>

//                 <div style={{
//                     display: "flex",
//                     gap: "5%",
//                     textAlign: "center",
//                     paddingLeft: "18%",

//                     paddingTop: '10px',
//                     marginBottom: '5px'
//                 }}>
//                     <Switch
//                         checked={checked}
//                         onChange={handleChangestate}
//                         inputProps={{ 'aria-label': 'controlled' }}
//                     />
//                 </div>

//                 <div style={{
//                     display: "flex",
//                     gap: "5%",
//                     paddingLeft: "18%",
//                     justifyContent: 'space-around',
//                     paddingTop: '3px',
//                 }}>

//                     {descriptionType && <div style={{ width: '100%' }}>
//                         {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
//                         <p style={{ paddingLeft: '5px' }}>Text Editor</p>
//                         <JoditEditor
//                             ref={editor}
//                             value={content}
//                             // onChange={() =>handletext}
//                             onChange={newContent => setContent(newContent)}
//                         />
//                     </div>}

//                     {!descriptionType && <div style={{ width: '100%' }}>
//                         {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
//                         <p style={{ paddingLeft: '5px' }}>Hmtl</p>

//                         <Textarea style={{ width: '100%', minHeight: '200px', overflowY: "scroll", overflowX: "hidden" }} value={htmlType} placeholder="Type anything…" />
//                         {/* <textarea style={{ width: '100%'}} value={htmlType} placeholder="Type anything…" /> */}

//                     </div>}
//                 </div>

//                 <div style={{
//                     display: "flex",
//                     gap: "20%",
//                     textAlign: "center",
//                     paddingLeft: "18%",
//                     justifyContent: 'space-around',
//                     paddingTop: '30px',
//                     marginBottom: '5px'
//                 }}>
//                     <div style={{ width: '100%' }}>
//                         <button style={{ padding: '8px' }}>Submit</button>
//                     </div>

//                 </div>

//             </form >
//             <ToastContainer />
//         </div >
//     )
// }
