import React, { useState, useRef, useMemo, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import * as htmlparser2 from "htmlparser2";
import axios from 'axios';
import AppConstatnt from "../../constants/AppConstant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Autocomplete from '@mui/material/Autocomplete';
import { useParams, useNavigate } from 'react-router-dom';
import "react-toastify/dist/ReactToastify.css";
import JoditEditor from 'jodit-react';
import Textarea from '@mui/joy/Textarea';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

let baseUrl = AppConstatnt.baseURL;

export default function Blogupdate() {

    const [personName, setPersonName] = React.useState([]);
    const [extid, setExtid] = useState('');
    const [typedValue, setTypedValue] = useState('');
    const [simtypedValue, setSimTypedValue] = useState('');
    const [metatitle, setMetatitle] = useState('');
    const [metadesc, setMetadesc] = useState('');
    const [title, setTitle] = useState('');
    const [author, setAuthor] = useState('');
    const [featuredimg, setFeaturedimg] = useState('');
    const [similarBlog, setSimilarBlog] = useState([]);
    const [readingTime, setReadingTime] = useState('');
    const [top100Films, setTop100Films] = useState([]);
    const [toplabels, setToplabels] = useState([]);
    const [similarones, setSimilarones] = useState([]);

    const [descriptionType, setDescriptionType] = useState(false);
    const [checked, setChecked] = useState(false);
    const [htmlType, setHtmlType] = useState(" ");
    const [content, setContent] = useState('');
    const editor = useRef(null);
    const theme = useTheme();

    const Navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (id) {
            updatecall(id);
        }
    }, [id])

    useEffect(() => {
        if (content) {
            handletext();
        }
    }, [content])


    useEffect(() => {
        if (simtypedValue) {
            const fetchDatasumilar = async () => {
                try {
                    // const labelname = extid.label;
                    // console.log(labelname, "labelname");
                    const extresponsesimilar = await axios.get('https://complete.crxinsider.com/complete?q=' + `${simtypedValue}`);
                    console.log(extresponsesimilar.data, "extresponse");
                    const mappedFilms = extresponsesimilar.data.map((item) => ({
                        label: item.name,
                        extid: item.ext_id,
                    }));
                    setSimilarones(mappedFilms);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
            fetchDatasumilar();
        }
    }, [simtypedValue]);

    useEffect(() => {
        if (top100Films.length > 0 && personName.length > 0) {
            similarblogupdate()
        }
    }, [personName, top100Films]);


    function similarblogupdate() {
        const selectedData = top100Films.filter(item => personName.includes(item.label));
        setSimilarBlog(selectedData);
    }

    const notify = (value) =>
        toast.success(value, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    const failure = (value) =>
        toast.error(value, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });




    const handleSimExtidChange = (event, newValue) => {
        console.log(newValue, "newValuesim");
        if (newValue) {
            setSimilarBlog(prevSimilarBlogs => [...prevSimilarBlogs, newValue]);
        }
    };

    const handleSimilarTypedValueChange = (event) => {
        const newValue = event.target.value;
        setSimTypedValue(newValue);
    };

    const handleDelete = (id) => {
        console.info(id, 'You clicked the delete icon.');
        const rslt = similarBlog.filter((item) => item.extid != id);
        setSimilarBlog(rslt);
    };

    const handleChangestate = (event) => {
        setChecked(event.target.checked);
        setDescriptionType(prev => !prev);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const blogdata = {
            ext_id: extid,
            meta_title: metatitle,
            meta_description: metadesc,
            title: title,
            featured_img: featuredimg,
            author: author,
            similar_blogs: similarBlog,
            reading_time: readingTime,
            html: htmlType
        };

        console.log(extid, metatitle, metadesc, title, author, featuredimg, similarBlog, readingTime, htmlType, " extid,metatitle, metadesc, title, author, featuredimg, similarBlog, readingTime, htmlType ")
        axios.patch(`${baseUrl}` + '/seo/editblog', { blogdata }).then((response) => {
            if (response.data.status == 200) {
                notify(response.data.message);
            }
            if (response.data.status == 500) {
                failure(response.data.message);
            }
            const timer = setTimeout(() => {
                Navigate('/blog')
            }, 2000);


        });
    }
    // /getSelectedblog/:id
    async function updatecall(id) {
        axios.get(`${baseUrl}` + `/seo/getSelectedblog/${id}`).then(({ data }) => {
            console.log(data, "datablogcheck");
            console.log(data.data[0].ext_id, "data.data[0].ext_id");
            setExtid(data.data[0].ext_id);
            setFeaturedimg(data.data[0].featured_img);
            setMetatitle(data.data[0].meta_title);
            setMetadesc(data.data[0].meta_description);
            setTitle(data.data[0].title);
            setAuthor(data.data[0].author);
            setSimilarBlog(JSON.parse(data.data[0].similar_blogs));
            // extractLabels(data.data[0].similar_blogs);
            setReadingTime(data.data[0].reading_time);
            setHtmlType(data.data[0].html);
        })
    }

    console.log(similarBlog, "similarblog")


    const handletext = () => {
        setHtmlType(content);
    };
    console.log(top100Films, "top100")
    console.log(toplabels, "toplabels");
    console.log(similarBlog, "similarBlog");
    console.log(personName, "personName");




    return (
        <div style={{ marginTop: "30px" }}>
            <div
                style={{
                    paddingTop: "4%",
                    display: "flex",
                    gap: "20%",
                    textAlign: "center",
                }}
            >
                <h1 style={{ paddingLeft: "18%", }}>Edit Blog</h1>
            </div>
            <form onSubmit={handleSubmit}>
                <div style={{
                    display: "flex",
                    gap: "20%",
                    textAlign: "center",
                    paddingLeft: "18%",
                    justifyContent: 'space-around',
                    marginBottom: '30px'
                }}>
                    <div style={{ width: '100%' }}>
                        {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
                        {/* <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={top100Films}
                            sx={{ width: '100 %' }}
                            value={extid} // Controlled component value
                            onChange={handleExtidChange}
                            renderInput={(params) => <TextField {...params} label="Ext Id" onChange={handleTypedValueChange} />}
                        /> */}
                        <TextField
                            helperText="Your blog ExtId "
                            id="demo-helper-text-aligned"
                            value={extid}
                            label="title"
                            fullWidth
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <TextField
                            helperText="Please enter your featured img"
                            id="demo-helper-text-aligned"
                            label="featured Img"
                            value={featuredimg}
                            fullWidth
                            onChange={(e) => { setFeaturedimg(e.target.value) }}
                        />
                    </div>
                </div>

                <div style={{
                    display: "flex",
                    gap: "20%",
                    textAlign: "center",
                    paddingLeft: "18%",
                    justifyContent: 'space-around'

                }}>
                    <div style={{ width: '100%' }}>
                        {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
                        <TextField
                            helperText="Please enter your meta title"
                            id="demo-helper-text-aligned"
                            label="meta_title"
                            value={metatitle}
                            fullWidth
                            onChange={(e) => {
                                setMetatitle(e.target.value);
                            }}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <TextField
                            helperText="Please enter your meta description"
                            id="demo-helper-text-aligned"
                            value={metadesc}
                            label="meta_description"
                            fullWidth
                            onChange={(e) => {
                                setMetadesc(e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div style={{
                    display: "flex",
                    gap: "20%",
                    textAlign: "center",
                    paddingLeft: "18%",
                    justifyContent: 'space-around',
                    paddingTop: '30px'

                }}>
                    <div style={{ width: '100%' }}>
                        {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
                        <TextField
                            helperText="Please enter your title for blog"
                            id="demo-helper-text-aligned"
                            value={title}
                            label="title"
                            fullWidth
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                        />
                    </div>
                    <div style={{ width: '100%' }}>
                        <TextField
                            helperText="Please enter author name"
                            id="demo-helper-text-aligned"
                            value={author}
                            label="author"
                            fullWidth
                            onChange={(e) => {
                                setAuthor(e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div style={{
                    display: "flex",
                    gap: "20%",
                    textAlign: "center",
                    paddingLeft: "18%",
                    justifyContent: 'space-around',
                    paddingTop: '30px'
                }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '100%' }}>
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={similarones}
                                sx={{ width: '100 %' }}
                                value={""} // Controlled component value
                                onChange={handleSimExtidChange}
                                renderInput={(params) => <TextField {...params} label="Simialar Id" onChange={handleSimilarTypedValueChange} />}
                            />
                        </div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <TextField
                            helperText="Please enter Reading Time"
                            id="demo-helper-text-aligned"
                            value={readingTime}
                            label="Reading Time"
                            fullWidth
                            onChange={(e) => {
                                setReadingTime(e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div style={{
                    display: "flex",
                    gap: "20%",
                    textAlign: "center",
                    paddingLeft: "18%",
                    justifyContent: 'space-around',
                    paddingTop: '5px',
                    marginBottom: '10px'
                }}>
                    <div style={{ width: '100%' }}>
                        <Stack direction="row" spacing={1}>
                            {similarBlog.length > 0 && similarBlog.map((item) => (
                                <Chip label={item.label} onDelete={() => { handleDelete(item.extid) }} />
                            ))}
                        </Stack>
                    </div>

                </div>


                <div style={{
                    display: "flex",
                    gap: "5%",
                    textAlign: "center",
                    paddingLeft: "18%",

                    paddingTop: '10px',
                    marginBottom: '5px'
                }}>
                    <Switch
                        checked={checked}
                        onChange={handleChangestate}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                </div>

                <div style={{
                    display: "flex",
                    gap: "5%",
                    paddingLeft: "18%",
                    justifyContent: 'space-around',
                    paddingTop: '3px',
                }}>

                    {descriptionType && <div style={{ width: '100%' }}>
                        {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
                        <p style={{ paddingLeft: '5px' }}>Text Editor</p>
                        <JoditEditor
                            ref={editor}
                            value={content}
                            // onChange={() =>handletext}
                            onChange={newContent => setContent(newContent)}
                        />
                    </div>}

                    {!descriptionType && <div style={{ width: '100%' }}>
                        {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
                        <p style={{ paddingLeft: '5px' }}>Hmtl</p>
                        <div style={{ width: '100%', height: '100px' }}>
                            <Textarea style={{ width: '100%', height: '100px' }} value={htmlType} placeholder="Type anything…" />
                        </div>
                    </div>}
                </div>

                <div style={{
                    display: "flex",
                    gap: "20%",
                    textAlign: "center",
                    paddingLeft: "18%",
                    justifyContent: 'space-around',
                    paddingTop: '30px',
                    marginBottom: '5px'
                }}>
                    <div style={{ width: '100%' }}>
                        <button style={{ padding: '8px' }}>Submit</button>
                    </div>

                </div>

            </form >
            <ToastContainer />
        </div >
    )
}











// import React, { useState, useRef, useMemo, useEffect } from 'react';
// import Switch from '@mui/material/Switch';
// import TextField from '@mui/material/TextField';
// import { useTheme } from '@mui/material/styles';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import * as htmlparser2 from "htmlparser2";
// import axios from 'axios';
// import AppConstatnt from "../../constants/AppConstant";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Autocomplete from '@mui/material/Autocomplete';
// import { useParams, useNavigate } from 'react-router-dom';
// import "react-toastify/dist/ReactToastify.css";
// import JoditEditor from 'jodit-react';
// import Textarea from '@mui/joy/Textarea';
// import Chip from '@mui/material/Chip';
// import Stack from '@mui/material/Stack';

// let baseUrl = AppConstatnt.baseURL;

// export default function Blogupdate() {

//     const [personName, setPersonName] = React.useState([]);
//     const [extid, setExtid] = useState('');
//     const [typedValue, setTypedValue] = useState('');
//     const [metatitle, setMetatitle] = useState('');
//     const [metadesc, setMetadesc] = useState('');
//     const [title, setTitle] = useState('');
//     const [author, setAuthor] = useState('');
//     const [featuredimg, setFeaturedimg] = useState('');
//     const [similarBlog, setSimilarBlog] = useState([]);
//     const [readingTime, setReadingTime] = useState('');
//     const [top100Films, setTop100Films] = useState([]);
//     const [toplabels, setToplabels] = useState([]);

//     const [descriptionType, setDescriptionType] = useState(false);
//     const [checked, setChecked] = useState(false);
//     const [htmlType, setHtmlType] = useState(" ");
//     const [content, setContent] = useState('');
//     const editor = useRef(null);
//     const theme = useTheme();

//     const ITEM_HEIGHT = 48;
//     const ITEM_PADDING_TOP = 8;
//     const MenuProps = {
//         PaperProps: {
//             style: {
//                 maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//                 width: 250,
//             },
//         },
//     };


//     function getStyles(name, personName, theme) {
//         return {
//             fontWeight:
//                 personName.indexOf(name) === -1
//                     ? theme.typography.fontWeightRegular
//                     : theme.typography.fontWeightMedium,
//         };
//     }


//     const Navigate = useNavigate();
//     const { id } = useParams();

//     useEffect(() => {
//         if (id) {
//             updatecall(id);
//         }
//     }, [id])

//     useEffect(() => {
//         if (content) {
//             handletext();
//         }
//     }, [content])

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const extresponse = await axios.get('https://complete.crxinsider.com/complete?q=' + `${id}`);
//                 console.log(extresponse.data, "extresponse");
//                 let targetExtension = extresponse.data.find(ext => ext.ext_id == id);

//                 // Extract the "name" value if the object is found
//                 if (targetExtension) {
//                     let nameValue = targetExtension.name;
//                     console.log(nameValue, "nameValue");
//                     let similarressponse = await axios.get('https://complete.crxinsider.com/complete?q=' + `${nameValue}`);
//                     let mappedFilmslabel = similarressponse.data.map((item) => {
//                         return item.name
//                     });
//                     setToplabels(mappedFilmslabel);
//                 } else {
//                     console.log("Extension not found.");
//                 }

//                 const mappedFilms = extresponse.data.map((item) => ({
//                     label: item.name,
//                     extid: item.ext_id,
//                 }));
//                 setTop100Films(mappedFilms);
//                 // const mappedFilmslabel = extresponse.data.map((item) => {
//                 //     return item.name
//                 // });
//                 // setToplabels(mappedFilmslabel);
//                 // console.log(mappedFilmslabel, "mappedFilmslabel")
//             } catch (error) {
//                 console.error('Error fetching data:', error);
//             }
//         };

//         const debounceInputVal = setTimeout(() => {
//             fetchData();
//         }, 300);

//         return () => clearTimeout(debounceInputVal);
//     }, [typedValue]);

//     useEffect(() => {
//         if (top100Films.length > 0 && personName.length > 0) {
//             similarblogupdate()
//         }
//     }, [personName, top100Films]);


//     function similarblogupdate() {
//         const selectedData = top100Films.filter(item => personName.includes(item.label));
//         setSimilarBlog(selectedData);
//     }

//     const notify = (value) =>
//         toast.success(value, {
//             position: "top-right",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//         });
//     const failure = (value) =>
//         toast.error(value, {
//             position: "top-right",
//             autoClose: 5000,
//             hideProgressBar: false,
//             closeOnClick: true,
//             pauseOnHover: true,
//             draggable: true,
//             progress: undefined,
//             theme: "light",
//         });


//     const handleExtidChange = (event, newValue) => {
//         console.log(newValue, "newValue");
//         setExtid(newValue);
//     };
//     console.log(toplabels, "toplabels");
//     const handleChange = (event) => {
//         const {
//             target: { value },
//         } = event;
//         setPersonName(
//             // On autofill we get a stringified value.
//             typeof value === 'string' ? value.split(',') : value,
//         );
//     };



//     const handleChangestate = (event) => {
//         setChecked(event.target.checked);
//         setDescriptionType(prev => !prev);
//     };

//     const handleTypedValueChange = (event) => {
//         const newValue = event.target.value;
//         setTypedValue(newValue);
//     };


//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const blogdata = {
//             ext_id: extid,
//             meta_title: metatitle,
//             meta_description: metadesc,
//             title: title,
//             featured_img: featuredimg,
//             author: author,
//             similar_blogs: similarBlog,
//             reading_time: readingTime,
//             html: htmlType
//         };

//         console.log(extid, metatitle, metadesc, title, author, featuredimg, similarBlog, readingTime, htmlType, " extid,metatitle, metadesc, title, author, featuredimg, similarBlog, readingTime, htmlType ")
//         axios.patch(`${baseUrl}` + '/seo/editblog', { blogdata }).then((response) => {
//             if (response.data.status == 200) {
//                 notify(response.data.message);
//             }
//             if (response.data.status == 500) {
//                 failure(response.data.message);
//             }
//             const timer = setTimeout(() => {
//                 Navigate('/blog')
//             }, 2000);


//         });
//     }
//     // /getSelectedblog/:id
//     async function updatecall(id) {
//         axios.get(`${baseUrl}` + `/seo/getSelectedblog/${id}`).then(({ data }) => {
//             console.log(data, "datablogcheck");
//             console.log(data.data[0].ext_id, "data.data[0].ext_id");
//             setExtid(data.data[0].ext_id);
//             setFeaturedimg(data.data[0].featured_img);
//             setMetatitle(data.data[0].meta_title);
//             setMetadesc(data.data[0].meta_description);
//             setTitle(data.data[0].title);
//             setAuthor(data.data[0].author);
//             // setSimilarBlog(JSON.parse(data.data[0].similar_blogs));
//             extractLabels(data.data[0].similar_blogs);
//             setReadingTime(data.data[0].reading_time);
//             setHtmlType(data.data[0].html);
//         })
//     }

//     console.log(similarBlog, "similarblog")

//     let extractLabels = (similarBlogsString) => {
//         try {
//             const similarBlogsArray = JSON.parse(similarBlogsString);
//             const valuearray = similarBlogsArray.map((item) => {
//                 return (item.label)
//             });
//             setPersonName(valuearray);
//         } catch (error) {
//             console.error("Error parsing similar_blogs string:", error);
//             return [];
//         }
//     };



//     const handletext = () => {
//         setHtmlType(content);
//     };
//     console.log(top100Films, "top100")
//     console.log(toplabels, "toplabels");
//     console.log(similarBlog, "similarBlog");
//     console.log(personName, "personName");


//     // const validPersonName = personName.map(name =>
//     //     top100Films.map(item => {
//     //         if (item.extid === name.extid) {
//     //             return item;
//     //         }
//     //     })
//     // );


//     return (
//         <div style={{ marginTop: "30px" }}>
//             <div
//                 style={{
//                     paddingTop: "4%",
//                     display: "flex",
//                     gap: "20%",
//                     textAlign: "center",
//                 }}
//             >
//                 <h1 style={{ paddingLeft: "18%", }}>Edit Blog</h1>
//             </div>
//             <form onSubmit={handleSubmit}>
//                 <div style={{
//                     display: "flex",
//                     gap: "20%",
//                     textAlign: "center",
//                     paddingLeft: "18%",
//                     justifyContent: 'space-around',
//                     marginBottom: '30px'
//                 }}>
//                     <div style={{ width: '100%' }}>
//                         {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
//                         {/* <Autocomplete
//                             disablePortal
//                             id="combo-box-demo"
//                             options={top100Films}
//                             sx={{ width: '100 %' }}
//                             value={extid} // Controlled component value
//                             onChange={handleExtidChange}
//                             renderInput={(params) => <TextField {...params} label="Ext Id" onChange={handleTypedValueChange} />}
//                         /> */}
//                         <TextField
//                             helperText="Your blog ExtId "
//                             id="demo-helper-text-aligned"
//                             value={extid}
//                             label="title"
//                             fullWidth
//                         />
//                     </div>
//                     <div style={{ width: '100%' }}>
//                         <TextField
//                             helperText="Please enter your featured img"
//                             id="demo-helper-text-aligned"
//                             label="featured Img"
//                             value={featuredimg}
//                             fullWidth
//                             onChange={(e) => { setFeaturedimg(e.target.value) }}
//                         />
//                     </div>
//                 </div>

//                 <div style={{
//                     display: "flex",
//                     gap: "20%",
//                     textAlign: "center",
//                     paddingLeft: "18%",
//                     justifyContent: 'space-around'

//                 }}>
//                     <div style={{ width: '100%' }}>
//                         {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
//                         <TextField
//                             helperText="Please enter your meta title"
//                             id="demo-helper-text-aligned"
//                             label="meta_title"
//                             value={metatitle}
//                             fullWidth
//                             onChange={(e) => {
//                                 setMetatitle(e.target.value);
//                             }}
//                         />
//                     </div>
//                     <div style={{ width: '100%' }}>
//                         <TextField
//                             helperText="Please enter your meta description"
//                             id="demo-helper-text-aligned"
//                             value={metadesc}
//                             label="meta_description"
//                             fullWidth
//                             onChange={(e) => {
//                                 setMetadesc(e.target.value);
//                             }}
//                         />
//                     </div>
//                 </div>

//                 <div style={{
//                     display: "flex",
//                     gap: "20%",
//                     textAlign: "center",
//                     paddingLeft: "18%",
//                     justifyContent: 'space-around',
//                     paddingTop: '30px'

//                 }}>
//                     <div style={{ width: '100%' }}>
//                         {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
//                         <TextField
//                             helperText="Please enter your title for blog"
//                             id="demo-helper-text-aligned"
//                             value={title}
//                             label="title"
//                             fullWidth
//                             onChange={(e) => {
//                                 setTitle(e.target.value);
//                             }}
//                         />
//                     </div>
//                     <div style={{ width: '100%' }}>
//                         <TextField
//                             helperText="Please enter author name"
//                             id="demo-helper-text-aligned"
//                             value={author}
//                             label="author"
//                             fullWidth
//                             onChange={(e) => {
//                                 setAuthor(e.target.value);
//                             }}
//                         />
//                     </div>
//                 </div>

//                 <div style={{
//                     display: "flex",
//                     gap: "20%",
//                     textAlign: "center",
//                     paddingLeft: "18%",
//                     justifyContent: 'space-around',
//                     paddingTop: '30px'
//                 }}>
//                     <div style={{ width: '100%' }}>
//                         <FormControl sx={{ m: 0, width: '100%' }}>
//                             <InputLabel id="demo-multiple-name-label" >Similar</InputLabel>
//                             <Select
//                                 labelId="demo-multiple-name-label"
//                                 id="demo-multiple-name"
//                                 multiple
//                                 value={personName}
//                                 onChange={handleChange}
//                                 input={<OutlinedInput label="Name" />}
//                                 MenuProps={MenuProps}
//                             >
//                                 {toplabels.map((name) => (
//                                     <MenuItem
//                                         key={name}
//                                         value={name}  // Passing the entire object as the value
//                                         style={getStyles(name, personName, theme)}
//                                     >
//                                         {name}
//                                     </MenuItem>
//                                 ))}
//                             </Select>
//                         </FormControl>
//                     </div>
//                     <div style={{ width: '100%' }}>
//                         <TextField
//                             helperText="Please enter Reading Time"
//                             id="demo-helper-text-aligned"
//                             value={readingTime}
//                             label="Reading Time"
//                             fullWidth
//                             onChange={(e) => {
//                                 setReadingTime(e.target.value);
//                             }}
//                         />
//                     </div>
//                 </div>


//                 <div style={{
//                     display: "flex",
//                     gap: "5%",
//                     textAlign: "center",
//                     paddingLeft: "18%",

//                     paddingTop: '10px',
//                     marginBottom: '5px'
//                 }}>
//                     <Switch
//                         checked={checked}
//                         onChange={handleChangestate}
//                         inputProps={{ 'aria-label': 'controlled' }}
//                     />
//                 </div>

//                 <div style={{
//                     display: "flex",
//                     gap: "5%",
//                     paddingLeft: "18%",
//                     justifyContent: 'space-around',
//                     paddingTop: '3px',
//                 }}>

//                     {descriptionType && <div style={{ width: '100%' }}>
//                         {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
//                         <p style={{ paddingLeft: '5px' }}>Text Editor</p>
//                         <JoditEditor
//                             ref={editor}
//                             value={content}
//                             // onChange={() =>handletext}
//                             onChange={newContent => setContent(newContent)}
//                         />
//                     </div>}

//                     {!descriptionType && <div style={{ width: '100%' }}>
//                         {/* <TextField fullWidth label="fullWidths" id="fullWidths" /> */}
//                         <p style={{ paddingLeft: '5px' }}>Hmtl</p>
//                         <div style={{ width: '100%', height: '100px' }}>
//                             <Textarea style={{ width: '100%', height: '100px' }} value={htmlType} placeholder="Type anything…" />
//                         </div>
//                     </div>}
//                 </div>

//                 <div style={{
//                     display: "flex",
//                     gap: "20%",
//                     textAlign: "center",
//                     paddingLeft: "18%",
//                     justifyContent: 'space-around',
//                     paddingTop: '30px',
//                     marginBottom: '5px'
//                 }}>
//                     <div style={{ width: '100%' }}>
//                         <button style={{ padding: '8px' }}>Submit</button>
//                     </div>

//                 </div>

//             </form >
//             <ToastContainer />
//         </div >
//     )
// }
