// "use strict";
Object.defineProperty(exports, "__esModule", {
  value: true,
});
if (process.env.NODE_ENV === "development") {
  exports.default = {
    panelURL: "https://localhost:3000/",
    // baseURL: "https://crxinsider.com/api",
    imageURL: "https://cdn.crxinsider.com/",
    baseURL: "http://localhost:3000/api",
    backendURL: "http://localhost:5600/api",
  };
} else {
  exports.default = {
    websiteURL: "https://team.crxinsider.com/",
    baseURL: "https://team.crxinsider.com/api",
    imageURL: "https://cdn.crxinsider.com/",
    // baseURL: "http://localhost:3000/api",
    backendURL: "https://team.crxinsider.com/api",
  };
}
