import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
// import InputLabel from "@mui/material/InputLabel";
// import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import dayjs from "dayjs";
import { DatePicker, Space } from "antd";
import axios from "axios";
import AppConstatnt from "../../constants/AppConstant";
import AllUserDatatables from "./AllUserDatatables";
const { RangePicker } = DatePicker;

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 22px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

let baseUrl = AppConstatnt.baseURL;

export default function AllUsers() {
  const [days, setDays] = React.useState(30);
  const [custom, setCustom] = useState(false);
  const [alluser, setAlluser] = useState([]);

  const handleChange = (event) => {
    if (event.target.value == "custom") {
      setCustom(true);
      setDays("custom");
    } else {
      setCustom(false);
      setDays(event.target.value);
    }
  };

  useEffect(() => {
    if (days != "custom") {
      function usercall() {
        const userdata = { days };
        console.log(days, "numbercheck");
        axios
          .post(`${baseUrl}` + "/users/allUsersdata", userdata)
          .then(({ data }) => {
            const formattedUsers = data.data.map((user, index) => {
              const date = new Date(user?.created_at);
              const formattedDate = `${date
                .getDate()
                .toString()
                .padStart(2, "0")}/${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")}/${date.getFullYear()}`;

              return {
                id: index + 1,
                uid: user.uid,
                name: user.name,
                Subscribed: user.subscribed_status,
                email: user.email,
                profile: user.profile,
                gid: user.gid,
                active: user.active,
                date: formattedDate,
              };
            });
            setAlluser(formattedUsers);
          });
      }
      usercall();
    }
  }, [days]);

  const disabledDate = (current) => {
    return current > dayjs().endOf("day");
  };

  const onChange = (value, dateString) => {
    // console.log("Formatted Selected Time: ", dateString);
    // console.log("Formatted Selected length: ", dateString.length);
    if (dateString[0] == "" && dateString[1] == "") {
      setCustom(false);
      setDays(30);
      return;
    }
    const date1 = new Date(dateString[0]);
    const date2 = new Date(dateString[1]);
    const timeDifference = date2 - date1;
    const dateDiffrence = timeDifference / (1000 * 60 * 60 * 24);
    setDays(dateDiffrence);
    console.log(dateDiffrence, "dateDiffrence");
  };

  return (
    <div>
      <div
        style={{
          paddingTop: "4%",
          display: "flex",
          gap: "20%",
          textAlign: "center",
        }}
      >
        <h1 style={{ paddingLeft: "18%" }}>All User</h1>
      </div>

      <div
        style={{
          marginRight: "13px",
          display: "flex",
          gap: "2%",
          textAlign: "center",
          justifyContent: "flex-end",
        }}
      >
        <div>
          <FormControl sx={{ m: 1 }} variant="standard">
            <NativeSelect
              id="demo-customized-select-native"
              value={days}
              onChange={handleChange}
              input={<BootstrapInput />}
            >
              <option aria-label="None" value="" />
              <option style={{ display: "none" }}>{days}</option>
              <option>7</option>
              <option>15</option>
              <option>30</option>
              <option>custom</option>
            </NativeSelect>
          </FormControl>
        </div>
        {custom && (
          <div
            style={{
              marginRight: "15px",
              display: "flex",
              gap: "20%",
              textAlign: "center",
              justifyContent: "flex-end",
              height: "61px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <RangePicker
              style={{ height: "45px" }}
              onChange={onChange}
              disabledDate={disabledDate}
            />
          </div>
        )}
      </div>
      <AllUserDatatables rows={alluser} />
    </div>
  );
}
