import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  Paper,
  Stack,
  Toolbar,
  Typography,
  Table,
  tableCellClasses,
  TableBody,
  TableRow,
  TableCell,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import { Input, message, Upload } from "antd";
import { useDispatch, useSelector } from "react-redux";
// import UploadIcon from "@mui/icons-material/Upload";
import {
  getImageTypeData,
  uploadImageData,
} from "../../redux/Slices/gallerySlice";
// import { getAllStoresData } from "../../redux/features/storesSlice";

const options = ["Option 1", "Option 2"];
function AddImage() {
  const dispatch = useDispatch();
  const { allStoresData } = useSelector((state) => ({ ...state.stores }));
  const { imageType } = useSelector((state) => ({ ...state.gallery }));

  // type setter
  const [formType, setFormType] = useState("url");
  const [image, setImage] = useState(null);
  const [ImageTypeList, setImageTypeList] = useState([]);
  const [value, setValue] = React.useState(imageType?.[0]?.type || "");
  const [inputValue, setInputValue] = React.useState("");

  const [imgSize, setImageSize] = useState({ width: "", height: "" });
  // form data
  const [imageData, setImageData] = useState({
    type: "",
    image_type: "",
    type_id: "",
    title: "",
    alt_text: "",
    img_url: "",
    upload_image: "",
  });

  // data handler
  const handleChange = (key) => (e) => {
    setImageData({ ...imageData, [key]: e.target.value });
  };
  console.log(imageData, "imageData------------");

  const handleImageType = (name) => {
    console.log(name, "name");
    if (imageType.length > 0) {
      for (var i = 0; i < imageType.length; i++) {
        // //console.log(categoryData[i])
        if (name === imageType[i].type) {
          setImageData({ ...imageData, image_type: imageType[i].type });
          setImageSize({
            width: imageType[i].master_width,
            height: imageType[i].master_height,
          });
        }
      }
    }
  };

  const onImageSelect = (e) => {
    try {
      console.log(e.target.files[0], "files---------------");
      setImage(e.target.files[0]);
      message.success("Image uploaded");
      // Clear the input value after the file is selected to avoid issues
      e.target.value = "";
    } catch (error) {
      console.log(error, "error---------------------");
    }
  };

  // handle form submit
  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log(event, "event");
    // console.log(image);
    console.log(imageData, "imageData");
    const formData = new FormData();
    if (imageData.img_url) formData.append("img_url", imageData.img_url);
    if (image) formData.append("file", image);
    formData.append("type", formType);
    formData.append("image_type", imageData.image_type);
    formData.append("type_id", 3);
    formData.append("store_name", imageData.store_name);
    formData.append("title", imageData.title);
    formData.append("alt_text", imageData.alt_text);

    console.log(formData, "after formdata");
    dispatch(uploadImageData(formData)).then((data) => {
      console.log(data, "code");
      if (data.payload?.code === 200) {
        toast.success("Image Uploaded Successfully");
      } else {
        toast.error("Something went wrong");
      }
    });
    // for (let pair of formData.entries()) {
    //   console.log(pair[0] + ',  ' + pair[1]);
    // }

    setImageData({
      type: "",
      image_type: "",
      type_id: "",
      store_name: "",
      title: "",
      alt_text: "",
      img_url: "",
      upload_image: "",
    });
    setImage();
  };

  useEffect(() => {
    dispatch(getImageTypeData());
    // dispatch(getAllStoresData());
  }, []);
  // console.log(imageType, "imageType----------------------");
  return (
    <div style={{ paddingLeft: "18%" }}>
      <div
        style={{
          paddingTop: "4%",
          display: "flex",
          gap: "20%",
          textAlign: "center",
          width: "100%",
        }}
      >
        <Paper
          style={{
            paddingTop: "4%",
            width: "100%",
          }}
          elevation={3}
        >
          <form onSubmit={handleSubmit}>
            <Stack spacing={3} direction="column">
              <Toolbar
                mt={3}
                sx={{
                  padding: "1.2rem",
                  borderBottom: "2px solid var(--primary-color)",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--primary-color)",
                    fontWeight: "bold",
                  }}
                  variant="h5"
                  id="tableTitle"
                >
                  Add Image
                </Typography>
              </Toolbar>

              <Table
                size="small"
                sx={{
                  width: "auto",
                  margin: "8px !important",
                  marginTop: "10px !important",
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none",
                  },
                }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Select Type</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <RadioGroup
                        name="image-type"
                        aria-labelledby="image-type"
                        row
                        value={formType}
                        onChange={(e) => {
                          imageData.type = formType;
                          setFormType(e.target.value);
                        }}
                      >
                        <FormControlLabel
                          control={<Radio />}
                          label="URL"
                          value="url"
                        />
                        <FormControlLabel
                          control={<Radio />}
                          label="Image"
                          value="image"
                        />
                      </RadioGroup>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Title</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="Enter Title"
                        value={imageData.title}
                        onChange={handleChange("title")}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Alt Text</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        fullWidth
                        placeholder="Enter alt_text"
                        value={imageData.alt_text}
                        onChange={handleChange("alt_text")}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">
                        <b>Image Type</b>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {/* <Autocomplete
                                                freeSolo
                                                size="small"
                                                id="user-combo-box"
                                                name="store_name"
                                                value={imageData.image_type}
                                                options={ImageTypeList}
                                                sx={{ width: 300 }}
                                                renderInput={(params) => (
                                                    <TextField {...params} placeholder="Select Image Type" />
                                                )}
                                                onChange={(e, val) => handleImageType(val)}
                                            /> */}
                      <Autocomplete
                        value={value}
                        onChange={(e, val) => handleImageType(val)}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        id="controllable-states-demo"
                        options={imageType.map((option) => option.type)}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Image Type" />
                        )}
                      />
                    </TableCell>
                  </TableRow>

                  {formType === "url" ? (
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6">
                          <b>Image URL</b>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <TextField
                          size="small"
                          fullWidth
                          placeholder="Enter image url"
                          value={imageData.img_url}
                          onChange={handleChange("img_url")}
                        />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell>
                        <Typography variant="h6">
                          <b>Upload Image</b>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {/* <Input
                          className="imageFile h-auto"
                          accept="image/png, image/gif, image/jpeg ,image/jpg"
                          required={true}
                          type="file"
                          id="img"
                          name="image"
                          onChange={onImageSelect}
                        /> */}
                        <input
                          type="file"
                          className="imageFile h-auto"
                          id="file-input img"
                          name="image"
                          onChange={onImageSelect}
                        />
                        {image && (
                          <div>
                            {/* Display the selected image */}
                            <img
                              alt="not found"
                              width={"250px"}
                              height={"250px"}
                              src={URL.createObjectURL(image)}
                            />
                            <br /> <br />
                            {/* Button to remove the selected image */}
                            <button onClick={() => setImage(null)}>
                              Remove
                            </button>
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  )}

                  <TableRow>
                    <TableCell colSpan={2}>
                      <Button
                        style={{ marginTop: "10px" }}
                        variant="contained"
                        type="submit"
                        size="large"
                        className="d-block mx-auto mt-4 mb-3"
                      >
                        <strong>Submit</strong>
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
          </form>
        </Paper>
      </div>
      <ToastContainer />
    </div>
  );
}

export default AddImage;
