import authSlice from "./Slices/authSlice";
import { configureStore } from "@reduxjs/toolkit";
import extSlice from "./Slices/extSlice";
import usersStatsSlice from "./Slices/usersStatsSlice";
import faqSlice from "./Slices/faqSlice";
import testimonialSlice from "./Slices/testimonialSlice";
import graphSlice from "./Slices/graphSlice";
import contactSlice from "./Slices/contactSlice";
import seoSlice from "./Slices/addSeoSlice";
import gallerySlice from "./Slices/gallerySlice";

const store = configureStore({
    reducer: {
        user: authSlice,
        ext: extSlice,
        stats: usersStatsSlice,
        faq: faqSlice,
        testimonial: testimonialSlice,
        gallery: gallerySlice,
        graphs: graphSlice,
        contact: contactSlice,
        seo: seoSlice
    }
})

export default store;