import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addedExts } from "../../../redux/Slices/graphSlice";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { Stack, Typography } from "@mui/material";
import { Skeleton } from "antd";
import { DotChartOutlined } from '@ant-design/icons';

Chart.register(ArcElement, Tooltip, Legend);

const AddedExts = () => {
  const auth = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const addedExtsData = useSelector((state) => state.graphs?.graphAddedExts);
  console.log(addedExtsData?.data, "AddedData");

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (auth) {
          dispatch(addedExts());
        }
      } catch (error) {
        console.log("Error fetching AddedData:", error);
      }
    };
    fetchData();
  }, [auth, dispatch]);

  const generatePieData = (data) => {
    return {
      labels: data.map((item) => item.category),
      datasets: [
        {
          label: "Exts",
          data: data.map((item) => item.total_ext),
          backgroundColor: ["#3498db", "#e74c3c", "#2ecc71", "#f39c12"],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
          ],
          borderWidth: 2,
          hoverBackgroundColor: ["#2980b9", "#c0392b", "#27ae60", "#d35400"],
        },
      ],
    };
  };

  const pieChartData = addedExtsData
    ? generatePieData(addedExtsData?.data)
    : null;

  return (
    <>
      <Stack>
        <Typography variant="h5" sx={{ textAlign: "center" }}>
          Added Extensions
        </Typography>
        {
          pieChartData ? (
            <div style={{ width: "300px", height: "300px" }}>
              {pieChartData && <Pie data={pieChartData} />}
            </div>
          ) : (
            <Skeleton.Node style={{ width: '300px', height: '100%' }} active>
              <DotChartOutlined style={{ fontSize: 80, color: '#bfbfbf' }} />
            </Skeleton.Node>
          )
        }
      </Stack>
    </>
  );
};

export default AddedExts;
