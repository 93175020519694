import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { removedExts } from '../../../redux/Slices/graphSlice'
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js'
import { Stack, Typography } from '@mui/material';
import { Skeleton } from "antd";
import { DotChartOutlined } from '@ant-design/icons';
Chart.register(ArcElement, Tooltip, Legend);

const RemovedExts = () => {
    const auth = useSelector((state) => state.user.isAuthenticated);
    const dispatch = useDispatch();
    const removedExtsData = useSelector((state) => state.graphs?.graphRemovedExts);
    console.log(removedExtsData?.data, "RemovedData");

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (auth) {
                    dispatch(removedExts());
                }
            } catch (error) {
                console.log("Error fetching RemovedData", error);
            }
        };
        fetchData();
    }, [auth, dispatch]);

    const generatePieData = (data) => {
        return {
            labels: data.map((item) => item.category
            ),
            datasets: [
                {
                    label: 'Exts',
                    data: data.map((item) => item.total_ext),
                    backgroundColor: ['#9b59b6', '#1abc9c', '#e67e22', '#34495e'],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                    ],
                    borderWidth: 2,
                    hoverBackgroundColor: ['#8e44ad', '#16a085', '#d35400', '#2c3e50']
                },
            ],
        };
    };

    const pieChartData = removedExtsData ? generatePieData(removedExtsData?.data) : null;

    return (
        <>
            <Stack >
                <Typography variant='h5' sx={{ textAlign: 'center' }}>Removed Extensions</Typography>
                {
                    pieChartData ? (
                        <div style={{ width: '300px', height: '300px' }}>
                            {pieChartData && <Pie data={pieChartData} />}
                        </div>
                    ) : (
                        <Skeleton.Node style={{ width: '300px', height: '100%' }} active>
                            <DotChartOutlined style={{ fontSize: 80, color: '#bfbfbf' }} />
                        </Skeleton.Node>
                    )
                }

            </Stack>
        </>
    )
}

export default RemovedExts
