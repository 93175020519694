import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getContactDetails, updateContactDetails } from "../api/contactapi";

export const getContacts = createAsyncThunk('get/allcontacts', async () => {
  try {
    const response = await getContactDetails();
    return response.data;
  } catch (error) {
    console.log(error.message, "errorrrrr");
    throw error;
  }
})

export const updateContactUs = createAsyncThunk('update/contactUs', async (formdata) => {
  try {
    const response = await updateContactDetails(formdata);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error
  }
})


const contactSlice = createSlice({
  name: 'contactSlice',
  initialState: {
    contactTable: null,
    message:""
  },
  extraReducers: (builder) => {
    builder.addCase(getContacts.fulfilled, (state, action) => {
      state.contactTable = action.payload;
    })
    builder.addCase(getContacts.rejected, (state, action) => {
      state.contactTable = null;
    })
    builder.addCase(updateContactUs.fulfilled,(state,action)=>{
      console.log(action.payload)
      state.message = action.payload.message;
  })
  builder.addCase(updateContactUs.rejected,(state,action)=>{
      state.message = "rejectedd";
  })
  }
})

export default contactSlice.reducer;