import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import * as galleryApi from "../api/galleryApi";

const initialState = {
  data: [],
  imageType: [],
  imageDimensions: [],
  loading: false,
  error: "",
  success: "",
};

// =========================================gallery data using asyncthunk====================================

export const getGalleryData = createAsyncThunk(
  "images/allimage",
  async (limit, { rejectedWithValue }) => {
    try {
      const resp = await galleryApi.getGalleryData(limit);
      if (resp.data) {
        // console.log(resp.data, "data from gallery testing")
        //data to be returned
        return resp.data.data;
      } else {
        console.log(resp.data.message, "error message from gallery testing");
        return rejectedWithValue(resp.data);
      }
    } catch (error) {
      rejectedWithValue(error.resp.data);
    }
  }
);

export const uploadImageData = createAsyncThunk(
  "images/uploadimage",
  async (formData, { rejectedWithValue }) => {
    try {
      const resp = await galleryApi.uploadImage(formData);
      console.log(resp,"response from upload image")
      if (resp.data.code == 200) {
        // console.log(resp.data, "data from gallery testing")
        message.success(
          resp.data.message ? resp.data.message : "imgae upload successfully"
        );
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "imgae upload successfully"
        );

        console.log(resp.data.message, "error message from gallery testing");
        return rejectedWithValue(resp.data);
      }
    } catch (error) {
      rejectedWithValue(error.resp.data);
    }
  }
);

export const deleteImageData = createAsyncThunk(
  "images/deleteimage",
  async ({ deletedData, cbFunc }, { rejectedWithValue }) => {
    try {
      const resp = await galleryApi.deleteImage(deletedData);
      if (resp.data.code === 200) {
        // console.log(resp.data, "data from gallery testing")
        message.success(
          resp.data.message ? resp.data.message : "imgae deleted successfully"
        );
        cbFunc();
        //data to be returned
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "imgae not deleted"
        );
        cbFunc();
        console.log(resp.data.message, "error message from gallery testing");
        return rejectedWithValue(resp.data);
      }
    } catch (error) {
      rejectedWithValue(error.resp.data);
    }
  }
);

export const getImageTypeData = createAsyncThunk(
  "images/imagetype",
  async (_, { rejectedWithValue }) => {
    try {
      const resp = await galleryApi.getImageType();
      if (resp.data) {
        // console.log(resp.data, "data from gallery testing");
        //data to be returned
        return resp.data.data;
      } else {
        console.log(resp.data.message, "error message from gallery testing");
        return rejectedWithValue(resp.data);
      }
    } catch (error) {
      rejectedWithValue(error.resp.data);
    }
  }
);

export const checkImageDimensionData = createAsyncThunk(
  "images/checkDimension",
  async (_, { rejectedWithValue }) => {
    try {
      const resp = await galleryApi.getGalleryData();
      if (resp.data) {
        // console.log(resp.data, "data from gallery testing")
        //data to be returned
        return resp.data.data;
      } else {
        console.log(resp.data.message, "error message from gallery testing");
        return rejectedWithValue(resp.data);
      }
    } catch (error) {
      rejectedWithValue(error.resp.data);
    }
  }
);

export const updateImageData = createAsyncThunk(
  "images/updateImage",
  async ({ dialogData, cbFunc }, { rejectedWithValue }) => {
    try {
      const resp = await galleryApi.updateImageData(dialogData);
      if (resp.data.code === 200) {
        message.success(
          resp.data.message ? resp.data.message : "imgae update successfully"
        );
        cbFunc();
        // console.log(resp.data, "data from gallery testing")
        //data to be returned
        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "imgae not updated"
        );
        cbFunc();
        console.log(resp.data.message, "error message from gallery testing");
        return rejectedWithValue(resp.data);
      }
    } catch (error) {
      rejectedWithValue(error.resp.data);
    }
  }
);

export const getImageBySearchData = createAsyncThunk(
  "images/searchImage",
  async (formData, { rejectedWithValue }) => {
    try {
      const resp = await galleryApi.getImageBySearch(formData);
      if (resp.data) {
        //data to be returned
        message.success(
          resp.data.message ? resp.data.message : "imgae searched data"
        );

        return resp.data;
      } else {
        message.error(
          resp.data.message ? resp.data.message : "imgae not getted"
        );

        console.log(resp.data.message, "error message from gallery testing");
        return rejectedWithValue(resp.data);
      }
    } catch (error) {
      rejectedWithValue(error.resp.data);
    }
  }
);

export const sendTelegramMessage = createAsyncThunk(
  "images/sendtelegrammsg",
  async (formData, { rejectedWithValue }) => {
    try {
      const resp = await galleryApi.sendTelegramMessageApi(formData);
      if (resp.data) {
        // console.log(resp.data, "data from gallery testing")
        message.success(
          resp.data.message ? resp.data.message : "Message send successfully"
        );
        return resp.data;
      } else {
        message.error(resp.data.message ? resp.data.message : "imgae not send");

        console.log(resp.data.message, "error message from gallery testing");
        return rejectedWithValue(resp.data);
      }
    } catch (error) {
      rejectedWithValue(error.resp.data);
    }
  }
);

const gallerySlice = createSlice({
  name: "images",
  initialState,

  extraReducers: (builder) => {
    // =================================getGalleryData=======================================
    builder.addCase(getGalleryData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getGalleryData.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getGalleryData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // =================================uploadImageData=======================================
    builder.addCase(uploadImageData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadImageData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(uploadImageData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // =================================deleteImageData=======================================
    builder.addCase(deleteImageData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteImageData.fulfilled, (state, action) => {
      state.loading = false;
      state.success = action.payload;
    });
    builder.addCase(deleteImageData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // =================================getImageTypeData=======================================
    builder.addCase(getImageTypeData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getImageTypeData.fulfilled, (state, action) => {
      state.loading = false;
      state.imageType = action.payload;
    });
    builder.addCase(getImageTypeData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // =================================checkImageDimensionData=======================================
    builder.addCase(checkImageDimensionData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(checkImageDimensionData.fulfilled, (state, action) => {
      state.loading = false;
      state.imageDimensions = action.payload;
    });
    builder.addCase(checkImageDimensionData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // =================================updateImageData=======================================
    builder.addCase(updateImageData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateImageData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateImageData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // =================================getImageBySearchData=======================================
    builder.addCase(getImageBySearchData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getImageBySearchData.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getImageBySearchData.rejected, (state, action) => {
      state.error = action.payload;
    });

    // ==================================sendTelegramMessage data=================================
    builder.addCase(sendTelegramMessage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendTelegramMessage.fulfilled, (state, action) => {
      state.telegramMsgs = action.payload.data;
      state.loading = false;
    });
    builder.addCase(sendTelegramMessage.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export default gallerySlice.reducer;
