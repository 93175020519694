import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  getAllTestimonial,
  createTestimonial,
  updateTestimonial,
  deleteTestimonial,
} from "../api/testimonilapi";

export const getTestimonials = createAsyncThunk(
  "/testimonials/get",
  async () => {
    try {
      const response = await getAllTestimonial();
      return response.data;
    } catch (error) {
      console.log(error, "err");
      throw error;
    }
  }
);

export const createTestimonials = createAsyncThunk(
  "/testimonials/create",
  async (formdata) => {
    try {
      const response = await createTestimonial(formdata);
      return response.data;
    } catch (error) {
      console.log(error, "err");
      throw error;
    }
  }
);

export const updateTestimonials = createAsyncThunk(
  "/testimonials/update",
  async (formdata) => {
    try {
      const response = await updateTestimonial(formdata);
      return response.data;
    } catch (error) {
      console.log(error, "err");
      throw error;
    }
  }
);

export const deleteTestimonials = createAsyncThunk(
  "/testimonials/delete",
  async (formdata, { rejectWithValue }) => {
    try {
      console.log(formdata, "slice");
      const response = await deleteTestimonial(formdata);
      console.log(response, "response in delete");
      return response.data;
    } catch (error) {
      console.log(error, "err");

      return rejectWithValue(error);
    }
  }
);

const testSlice = createSlice({
  name: "testSlice",
  initialState: {
    testTable: null,
    message: "",
  },
  extraReducers: (builder) => {
    builder.addCase(getTestimonials.fulfilled, (state, action) => {
      state.testTable = action.payload;
    });
    builder.addCase(getTestimonials.rejected, (state) => {
      state.testTable = null;
    });

    builder.addCase(createTestimonials.fulfilled, (state, action) => {
      state.message = action.payload.message;
    });
    builder.addCase(createTestimonials.rejected, (state) => {
      state.message = "";
    });

    builder.addCase(updateTestimonials.fulfilled, (state, action) => {
      state.message = action.payload.message;
    });
    builder.addCase(updateTestimonials.rejected, (state) => {
      state.message = "";
    });

    builder.addCase(deleteTestimonials.fulfilled, (state, action) => {
      state.message = action.payload.message;
    });
    builder.addCase(deleteTestimonials.rejected, (state) => {
      state.message = "";
    });
  },
});

export default testSlice.reducer;
