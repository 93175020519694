import axios from "axios";
import AppConstatnt from "../../constants/AppConstant";

const API = axios.create({ baseURL: `${AppConstatnt.baseURL}` });

export const getAllSeoDetails = () => API.get("/seo/getAllSeoDetails");
export const getSelectedSeoDetails = (formdata) =>
  API.get("/seo/getSelectedSeoDetails/" + `${formdata}`);
export const createSeoDetails = (formdata) =>
  API.post("/seo/createSeoDetails", formdata);
export const updateSeoDetails = (formdata) =>
  API.put("/seo/updateSeoDetails", formdata);
export const deleteSeoDetails = (formdata) =>
  API.delete("/seo/deleteSeoDetails", { data: formdata });
