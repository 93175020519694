import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateSeo, deleteSeo } from "../../redux/Slices/addSeoSlice";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function DataTable({ rows, setUpdate }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notify = (value) =>
    toast.success(value, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const handleblock = async (id) => {
    const data = {
      active: 0,
      id,
    };
    dispatch(deleteSeo(data)).then((response) => {
      if (response.payload.code) {
        notify(response.payload.message);
      }
    });
    setUpdate((prev) => !prev);
  };

  const handleunblock = async (id) => {
    const data = {
      active: 1,
      id,
    };
    dispatch(deleteSeo(data)).then((response) => {
      if (response.payload.code) {
        notify(response.payload.message);
      }
    });
    setUpdate((prev) => !prev);
  };

  const editSeo = async (id) => {
    console.log(id);
    navigate(`/seo/${id}`);
  };

  const columns = [
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: "sl", headerName: "Sl", width: 70 },
    { field: "title", headerName: "Title", width: 130 },
    { field: "description", headerName: "Description", width: 130 },
    { field: "data", headerName: "Data", width: 230 },
    { field: "active", headerName: "Active", width: 130 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            className="action flex gap-1"
            style={{ display: "flex", gap: "10px" }}
          >
            <div className="view ">
              <button
                onClick={(ev) => {
                  editSeo(params.row.id);
                }}
                className="bg-green-500"
              >
                <ModeIcon sx={{ width: "15px", height: "15px" }} />
              </button>
            </div>
            <div className="view">
              {params.row.active == 1 && (
                <div className="view">
                  <button
                    className="bg-red-500 rounded-full "
                    onClick={() => handleblock(params.row.id)}
                  >
                    Deactivate
                  </button>
                </div>
              )}

              {params.row.active == 0 && (
                <div className="view" style={{ width: "88px" }}>
                  <button
                    style={{ width: "100%" }}
                    className="bg-red-500 rounded-full"
                    onClick={() => handleunblock(params.row.id)}
                  >
                    Activate
                  </button>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
  ];

  {
    /* <DeleteIcon sx={{ width: "15px", height: "15px" }} /> */
  }

  return (
    <div
      style={{
        paddingTop: "5%",
        display: "flex",
        gap: "20%",
        textAlign: "center",
      }}
    >
      <div
        className="left"
        style={{
          paddingTop: "20px",
          flex: 1,
          marginLeft: "18%",
          overflowX: "scroll",
          marginRight: "15px",
        }}
      >
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows}
            disableRowSelectionOnClick
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 50 },
              },
            }}
            pageSizeOptions={[50, 100]}
            checkboxSelection
          />
        </div>
      </div>
    </div>
  );
}
