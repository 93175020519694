import { useRef, useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Stack, Typography, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { dailySignupUsers } from "../../../redux/Slices/graphSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import "chart.js/auto";

const DailySignupUsers = () => {
  const ref = useRef();
  const auth = useSelector((state) => state.user.isAuthenticated);
  const dispatch = useDispatch();
  const DailySignupUsersData = useSelector(
    (state) => state.graphs?.graphDailySignupUsers
  );
  console.log(DailySignupUsersData?.data, "Daily Signup Users Data");

  const today = new Date(); // Gets today's date
  today.setDate(today.getDate() - 7); // Sets the date to the previous day
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so +1 is needed
  const day = String(today.getDate()).padStart(2, "0");
  const yesterday = `${year}-${month}-${day}`;
  let today_date = new Date().toISOString().slice(0, 10);
  const [startDate, setStartDate] = useState(dayjs(yesterday) || null);
  const [endDate, setEndDate] = useState(dayjs(today_date) || null);
  useEffect(() => {
    const fetchData = async () => {
      const formData = {
        startDate: startDate,
        endDate: endDate,
      };
      try {
        if (auth) {
          dispatch(dailySignupUsers(formData));
          console.log("i am daily signup", formData);
        }
      } catch (error) {
        console.log("error while fetching dailyLoginUsers", error);
      }
    };
    fetchData();
  }, [auth, dispatch, startDate, endDate]);

  const generateDailySignupData = (data) => {
    return {
      labels: data.map((item) => item.date),
      datasets: [
        {
          label: "Signup Users",
          data: data.map((item) => item.user_count),
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
        },
      ],
    };
  };
  const lineChartData = DailySignupUsersData
    ? generateDailySignupData(DailySignupUsersData?.data)
    : null;
  return (
    <>
      <Stack sx={{ paddingBottom: "30px" }}>
        <Typography variant="h4" sx={{ textAlign: "center", padding: "30px" }}>
          Daily Signup Users
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="datePicker">
            <DatePicker
              sx={{
                margin: "20px",
              }}
              label="Start Date"
              value={startDate}
              onChange={(newvalue) =>
                setStartDate(dayjs(newvalue.$d).format("YYYY-MM-DD"))
              }
              inputFormat="yyyy/mm/dd"
              renderInput={(params) => <TextField {...params} />}
            />
            <DatePicker
              sx={{
                margin: "20px",
              }}
              label="End Date"
              value={endDate}
              onChange={(newvalue) =>
                setEndDate(dayjs(newvalue.$d).format("YYYY-MM-DD"))
              }
              inputFormat="yyyy/mm/dd"
              renderInput={(params) => <TextField {...params} />}
            />
          </div>
        </LocalizationProvider>

        {/* <Line ref={ref} data={data} /> */}
        {lineChartData && <Line ref={ref} data={lineChartData} />}
      </Stack>
    </>
  );
};

export default DailySignupUsers;
