import axios from "axios";
import AppConstatnt from "../../constants/AppConstant";

const API = axios.create({ baseURL: `${AppConstatnt.baseURL}` });

export const getAllExt = (formdata) => API.post("/ext/allextensions",formdata);

export const getRecentlyAddedExt = (formdata) =>
  API.post("/ext/recentlyAddedExtensions", formdata);
export const getRecentlyUpdatedExt = (formdata) =>
  API.post("/ext/recentlyUpdatedExtensions", formdata);
export const getRecentlyRemovedExt = (formdata) =>
  API.post("/ext/recentlyremovedExtensions", formdata);
export const getExtensionByCategory = (formdata) =>
  API.post("/ext/extbycat", formdata);
export const mostDownloadedExt = (formdata) =>
  API.post("/ext/mostdownloaded", formdata);
export const getMenifestExt = (formdata) => API.post("/ext/manifest", formdata);
