import React from 'react';
import { Stack, Paper, Box } from '@mui/material';
import AddedExts from './Pie Charts/AddedExts'
import RemovedExts from './Pie Charts/RemovedExts'
import UpdatedExts from './Pie Charts/UpdatedExts'
import Divider from '@mui/material/Divider';
import DailyLoginUsers from './Line Chart/DailyLoginUsers'
import DailySignupUsers from './Line Chart/DailySignupUsers'


const Dashboard = () => {

  return (
    <>
      <Paper sx={{ display: 'flex', justifyContent: 'center', width: '80%', float: 'right', overflow: 'auto', marginTop: '8%', marginRight: '23px' }}>
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={4} sx={{ padding: '30px', height: '350px' }}>
          <AddedExts />
          <UpdatedExts />
          <RemovedExts />
        </Stack>
      </Paper>
      <Paper sx={{ width: '80%', float: 'right', overflow: 'auto', marginRight: '23px', marginTop: '3%' }}>
        <DailyLoginUsers />
      </Paper>
      <Paper sx={{ width: '80%', float: 'right', overflow: 'auto', marginRight: '23px', marginTop: '3%' }}>
        <DailySignupUsers />
      </Paper>

    </>
  )
}

export default Dashboard